import { TreknLogo } from "@/components/common";

export function WalletOptions() {
  return (
    <section className="px-5 w-full h-screen">
      <div className="flex items-center justify-center w-full h-1/2">
        <TreknLogo className="translate-y-0" />
      </div>
      <div className="space-y-4">
        {/* <Link
          className="block"
          to={ROUTE.CONNECT_BITGET}
          target="_blank"
          onClick={() => {
            ReactGA.event({
              category: "Button Clicks",
              action: "connect_bitget",
              label: "Connect Bitget",
            });
          }}
        >
          <Button className="w-full !bg-main" icon={<img src={BitgetIcon} alt="Bitget Icon" className="w-5 h-5" />}>
            Bitget wallet
          </Button>
        </Link> */}
      </div>
    </section>
  );
}
