import { Button } from "@/components/common";
import { FRIEND, HOME } from "@/const/path.const";
import { useStore } from "@/store/useStore";
import { TreknLogoImg } from "public/Images";
import CountUp from "react-countup";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

export function ButtonHeader() {
  const { points, prePoints } = useStore();
  return (
    <div className="flex items-center justify-between">
      <Link to={FRIEND.ROOT}>
        <Button
          icon={<FaUsers size={20} />}
          className="!rounded-full justify-center !bg-[#3D3D3D]/50 text-sm"
        ></Button>
      </Link>
      <Link to={HOME.MY_POINTS}>
        <Button className="h-12 justify-center !bg-[#3D3D3D]/50 !rounded-full">
          <div className="flex items-center space-x-2">
            <p>
              <CountUp start={prePoints} end={points || 0} duration={1} decimals={points ? 0 : 1} />
            </p>
            <img src={TreknLogoImg} alt="Trekn" className="w-6 h-6" />
          </div>
        </Button>
      </Link>
    </div>
  );
}
