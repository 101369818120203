import { useCaptureContext } from "@/context/CaptureContext";
import { useStore } from "@/store/useStore";
import React, { useEffect, useState, useRef } from "react";

export default function CaptureImage() {
  const { form, setForm } = useCaptureContext();
  const [maxLength, setMaxLength] = useState(100);
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { contextTags } = useStore();

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const inputWidth = inputRef.current.offsetWidth;
      const textWidth = spanRef.current.offsetWidth;

      if (textWidth >= inputWidth - 60) {
        setMaxLength(form.content.length);
      }
    }
  }, [form.content]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className="absolute left-0 right-0 top-0 bottom-0">
      <img src={form.image} alt="Captured" className="w-full h-full object-cover" />
      {form?.nft && <img src={form.nft} alt="NFT" className="absolute w-[25vw] h-[25vw] bottom-3" />}
      <input
        ref={inputRef}
        onChange={(e) => {
          setForm({ ...form, content: e.target.value });
        }}
        type="text"
        onKeyDown={handleKeyDown}
        value={form.content + contextTags}
        maxLength={maxLength}
        placeholder={`Write something about it? ${contextTags}`}
        className="bg-[#00000080] text-center rounded-full active:outline-none focus:outline-none absolute bottom-3 left-6 right-6 py-1 px-3"
        style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      />
      <span
        ref={spanRef}
        style={{
          position: "absolute",
          visibility: "hidden",
          whiteSpace: "nowrap",
          fontSize: "inherit",
          fontFamily: "inherit",
          padding: "inherit",
        }}
      >
        {form.content}
      </span>
    </div>
  );
}
