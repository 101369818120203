import { Meme1, Meme2 } from "public/Images";
import React from "react";

export function StepThree() {
  return (
    <div className="px-8">
      <h1 className="text-onboard text-base text-center font-semibold leading-9">Keep it fun,</h1>
      <h1 className="text-onboard text-base text-center font-semibold leading-9">keep it IRL</h1>
      <p className="text-center text-onboard/70 mt-4 px-2">
        Capture photos by different themes, share, donate to friends' moments, and more features coming soon..
      </p>
      <div className="relative mt-16 flex justify-center w-full">
        <img
          src={Meme1}
          className="w-[200px] h-[200px] -rotate-[22deg] left-4 absolute border-8 border-white object-cover"
          alt="MEME"
        />
        <img
          src={Meme2}
          className="w-[230px] h-[230px] rotate-[10.53deg] right-2 -top-8 absolute border-8 border-white"
          alt="MEME"
        />
      </div>
    </div>
  );
}
