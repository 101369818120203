import React from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: "image" | "html";
}

export function Card({ children, style, className, type = "html" }: CardProps) {
  return (
    <div
      className={`${
        type === "html" ? "px-6 py-4 border-[1.2px]" : "p-2 h-48 border object-contain"
      } rounded-xl border-[#444444] ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
