import { TRef } from "@/types";
import { ImmerStateCreator } from "./useStore";
import { FriendRefService, LoginService } from "@/services";

export type FriendsRefSlice = {
  FriendList: TRef[];
  RefList: TRef[];
  FriendListLoading: boolean;
  fetchFriendList: () => Promise<void>;
};

export const createFriendRefSlice: ImmerStateCreator<FriendsRefSlice> = (set) => ({
  FriendList: [],
  RefList: [],
  FriendListLoading: false,

  fetchFriendList: async () => {
    set((state) => {
      state.FriendListLoading = true;
    });

    const myId = (await LoginService.me()).metadata.id;
    // FriendRefService.getReferralList(myId, { page: 1, limit: 10 });
    const friendsRef = await FriendRefService.getReferralList(myId, { page: 1, limit: 10 });

    set((state) => {
      // state.FriendRef = friendsRef.metadata.data;
      state.FriendList = friendsRef.metadata.data;
      // state.FriendRefLoading = false;
      state.FriendListLoading = false;
    });
  },
});
