import { Avatar } from "@/components/common";
import { TCommunity } from "@/types/community.type";

export function CommunityInfo({ data, isMember }: { data: TCommunity; isMember: boolean }) {
  return (
    <div className="flex flex-col items-center justify-center px-4">
      <Avatar src={data.avatar} type="square" className="w-24 h-24" />
      <h1 className="mt-5 text-base font-semibold">{data.name}</h1>
      {isMember && <p className="mt-2 text-secondary text-small">You’re member of this community</p>}
      <div className="flex items-center justify-between w-full mt-8">
        <div className="text-center space-y-2 w-20">
          <p>Posts</p>
          <p className="text-secondary text-small">{data.posts.length}</p>
        </div>
        <div className="text-center space-y-2 w-20">
          <p>Followers</p>
          <p className="text-secondary text-small">{data.follows.length}</p>
        </div>
        <div className="text-center space-y-2 w-20">
          <p>Members</p>
          <p className="text-secondary text-small">{data.members.length}</p>
        </div>
      </div>
    </div>
  );
}
