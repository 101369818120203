import { Button, Input } from "@/components/common";
import { useAnimatedVirtualKeyboard } from "@/hooks/common-hooks";
import { UserService } from "@/services/user/user.service";
import { useRef, useState } from "react";
import { Sheet, SheetRef } from "react-modal-sheet";

type EmailPopupProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

enum STEP {
  EMAIL,
  VERIFY,
}

export default function EmailPopup({ open, setOpen }: EmailPopupProps) {
  const [step, setStep] = useState<STEP>(STEP.EMAIL);
  const [email, setEmail] = useState<string>("");
  const sheetRef = useRef<SheetRef>();

  const { keyboardHeight } = useAnimatedVirtualKeyboard();

  const handleSendEmail = async () => {
    setStep(STEP.VERIFY);
    if (!email) return;

    await UserService.linkMailAccount(email);
  };

  return (
    <Sheet ref={sheetRef} isOpen={open} onClose={() => setOpen(false)} detent="content-height">
      <Sheet.Container style={{ paddingBottom: keyboardHeight }} className="!bg-main">
        <Sheet.Header />

        <Sheet.Content className="mb-10">
          {step === STEP.EMAIL && (
            <div className="px-6 py-4">
              <h1 className="text-white font-medium mb-2">Type your email here</h1>
              <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
              <Button disabled={!email} onClick={handleSendEmail} className="mt-8 w-full justify-center bg-[#383838]">
                Verify
              </Button>
            </div>
          )}
          {step === STEP.VERIFY && (
            <div className="px-6 py-4">
              <h1 className="text-white font-medium mb-2">We have sent you an email</h1>
              <p className="text-gray-400">
                Verification link has been sent to your email. Please check your inbox to verify.
              </p>
              <Button onClick={() => setOpen(false)} className="mt-8 w-full justify-center bg-[#383838]">
                Cancel
              </Button>
            </div>
          )}
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop />
    </Sheet>
  );
}
