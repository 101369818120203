import { BackButton } from "@/components/common";
import { useState } from "react";
import CommunityComponent from "./components/CommunityComponent";
import FriendComponent from "./components/FriendComponent";

export default function Connection() {
  const [currentView, setCurrentView] = useState("friend");
  return (
    <section
      className="p-4 !bg-fixed min-h-screen"
      style={{ background: "linear-gradient(180deg, rgba(43,78,31,1) 0%, rgba(0,0,0,1) 10rem)" }}
    >
      <div className="mt-4 flex items-center relative">
        <BackButton />
        <p className="text-medium leading-medium absolute left-1/2 -translate-x-1/2">Connection</p>
      </div>
      <div className="flex items-center gap-6 mt-8">
        <p
          className={`${
            currentView !== "friend" && "text-[#FFFFFF80]"
          } font-semibold text-heading-base leading-heading-base`}
          onClick={() => {
            setCurrentView("friend");
          }}
        >
          Friends
        </p>
        <p
          className={`${
            currentView !== "community" && "text-[#FFFFFF80]"
          } font-semibold text-heading-base leading-heading-base`}
          onClick={() => {
            setCurrentView("community");
          }}
        >
          Communities
        </p>
      </div>
      <div className="mt-5">
        {currentView === "friend" && <FriendComponent />}
        {currentView === "community" && <CommunityComponent/>}
      </div>
    </section>
  );
}
