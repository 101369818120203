import { UserService } from "@/services/user/user.service";
import React, { ChangeEvent, useState } from "react";
import { FaEdit } from "react-icons/fa";

interface AvatarInputProps {
  defaultImage?: string;
  onChange?: (image: string) => void;
}

const AvatarInput: React.FC<AvatarInputProps> = ({ defaultImage, onChange }) => {
  const [image, setImage] = useState<string | undefined>(defaultImage);

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("file", e.target.files![0]);

    const res = await UserService.uploadAvatar(formData);
    if (res.success) {
      setImage(res.metadata.url);
      onChange && onChange(res.metadata.url);
    } else {
      alert("Something went wrong, please try again later!");
    }
  };

  return (
    <div className="relative inline-block">
      <img
        src={image}
        alt="Avatar"
        className="w-24 h-24 rounded-full object-cover cursor-pointer"
        onClick={() => document.getElementById("file-input")?.click()}
      />
      <FaEdit size={20} className="absolute right-1 bottom-1" />
      <input
        type="file"
        id="file-input"
        className="absolute inset-0 opacity-0 cursor-pointer"
        onChange={handleImageChange}
        accept="image/*"
      />
    </div>
  );
};

export { AvatarInput };
