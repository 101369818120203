import { HorizontalScroll, SmallPost } from "@/components/common";
import { CommunityEnum } from "@/const/app.const";
import { EXPLORE } from "@/const/path.const";
import { TPost } from "@/types";
import { Link } from "react-router-dom";

export function MyPosts({ items }: { items: TPost[] }) {
  return items && items.length > 0 ? (
    <div>
      <div className="flex items-center justify-between text-small py-2">
        <p className="text-gray-1">My moments</p>
        <Link to={`${EXPLORE.COMMUNITIES}?type=${CommunityEnum.ME}`}>View all</Link>
      </div>
      <HorizontalScroll>
        {items.map((item) => (
          <SmallPost showName={false} key={item.id} item={item} />
        ))}
      </HorizontalScroll>
    </div>
  ) : null;
}
