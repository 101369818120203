import { UserService } from "@/services/user/user.service";
import { WALLET_NAME } from "@/types";
import { encryptData } from "@/utils/blockchain.utils";
import { Keypair } from "@solana/web3.js";
import { generateMnemonic, mnemonicToSeed } from "bip39";
import bs58 from "bs58";
import { derivePath } from "ed25519-hd-key";
import { useEffect, useState } from "react";

type CreateWallet = {
  pubkey: string;
  secret: string;
  keypair: Keypair;
};

export const useCreateWallet = () => {
  const [seedPhrase, setSeedPhrase] = useState<string | null>(null);
  const [wallet, setWallet] = useState<CreateWallet | null>(null);

  useEffect(() => {
    const generateSeed = async () => {
      const mnemonic = generateMnemonic();
      setSeedPhrase(mnemonic);
      const seed = await mnemonicToSeed(mnemonic);
      const wallet = await createSolWallet(seed);
      setWallet(wallet);
    };

    generateSeed();
  }, []);

  return { seedPhrase, wallet };
};

export const storageAndUpdateWallet = async (wallet: CreateWallet, walletName: string) => {
  const encryptSecret = encryptData(wallet.secret);

  const data = {
    address: wallet.pubkey,
    name: walletName,
    wallet_name: WALLET_NAME.CREATE,
  };

  const res = await UserService.createWallet(data);

  if (res.success) {
    const wallets = JSON.parse(localStorage.getItem("wallets") || "[]");
    const newWallets = [...wallets, { id: res.metadata.id, secret: encryptSecret }];
    localStorage.setItem("wallets", JSON.stringify(newWallets));
    return true;
  } else {
    return false;
  }
};

const createSolWallet = async (seed: Buffer) => {
  const DERIVATION_PATH = "m/44'/501'/0'/0'";
  const derivedSeed = derivePath(DERIVATION_PATH, seed.toString("hex")).key;

  const keypair = Keypair.fromSeed(new Uint8Array(derivedSeed));

  const pubkey = keypair.publicKey.toBase58();
  const secret = bs58.encode(keypair.secretKey);

  return { pubkey, secret, keypair } as CreateWallet;
};
