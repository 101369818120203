import React, { useEffect, useState } from "react";
import { FriendsPosts, MyPosts, PublicPosts } from ".";
import { AcceptFriendModal } from "./AcceptFriendModal";
import { useStore } from "@/store/useStore";
import { FRIEND_CONFIRM_STATUS, REF_TYPE } from "@/const/app.const";
import { TfiPlus } from "react-icons/tfi";

function Moment() {
  const { HomeAllPost, HomeMyPost, HomeFriendPost, HomeLoading: loading, user } = useStore();
  const [openFriendModal, setOpenFriendModal] = useState(false);

  const checkReferral = async () => {
    const rf = localStorage.getItem("refFriend");
    if (rf) {
      const rf_data = JSON.parse(rf);
      if (
        rf_data.type === REF_TYPE.USER &&
        user!.id !== rf_data.id &&
        rf_data.status === FRIEND_CONFIRM_STATUS.PENDING
      ) {
        setOpenFriendModal(true);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("refFriend") && user) {
      checkReferral();
    }
  }, []);
  return (
    <section className="w-full">
      <div
        className="absolute w-full h-screen"
        style={{ background: "linear-gradient(180deg, rgba(43,78,31,1) 0%, rgba(0,0,0,1) 10rem)" }}
      />
      {/* <WhitelistBanner /> */}
      <div className="py-6 relative w-full">
        <div className="flex w-full px-4 justify-between items-center">
          <span className="font-bold text-xl text-white">Moments</span>
          <div className="w-12 h-12 rounded-full bg-[#7F7F7F66] flex justify-center items-center text-white">
            <TfiPlus className="w-6 h-6"/>
          </div>
        </div>
        <div className="absolute p-4 w-full h-screen overflow-auto">
          <div className="mt-8 space-y-3">
            <PublicPosts items={HomeAllPost} loading={loading} />
            <MyPosts items={HomeMyPost} />
            <FriendsPosts items={HomeFriendPost} />
          </div>
        </div>
        <AcceptFriendModal open={openFriendModal} setOpen={setOpenFriendModal} />
      </div>
    </section>
  );
}

export default Moment;
