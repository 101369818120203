import { CircleLoading } from "@/components/common";
import Drawer from "@/components/common/Drawer/Drawer";
import { useCaptureContext } from "@/context/CaptureContext";
import { useConnectWalletContext } from "@/context/ConnectWalletContext";
import { CommunityInterface } from "@/models/community.model";
import { CommunityService } from "@/services";
import { useStore } from "@/store/useStore";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const CommunityItem = ({
  data,
  onClick,
  active,
}: {
  data: CommunityInterface;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <div className="flex items-center justify-between p-1">
      <div className="flex items-center gap-2">
        <img src={data.avatar!} className="w-12 h-12 rounded-xl" />
        <p className="text-medium leading-medium">{data.name}</p>
      </div>
      <Button label={active ? "Untag" : "Tag"} onClick={onClick} />
    </div>
  );
};

const Button = ({ label, onClick }: { label: string; onClick: () => void }) => {
  return (
    <div className="py-2 px-4 bg-neutral-hover rounded-xl active:opacity-80" onClick={onClick}>
      {label}
    </div>
  );
};

export default function SelectCommunity({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { form, setForm } = useCaptureContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [communityList, setCommunityList] = useState<CommunityInterface[]>([]);
  const isHaveCommunityItem = (item: CommunityInterface) => form.communityIds.some((_item) => _item.id === item.id);
  const { user } = useStore();

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (user) {
        const communities: any = await CommunityService.getCommunityByUserId(user.id);
        setCommunityList(communities);
        setForm({ ...form, communityIds: [...communities] });
      }
      setLoading(false);
    })();
  }, []);
  return (
    <Drawer isOpen={isOpen} setIsOpen={onClose}>
      <div className="flex relative items-center justify-center">
        <p className="font-bold text-medium leading-medium">Tag communities</p>
        <div className="absolute right-0" onClick={onClose}>
          <p className="font-bold text-medium leading-medium">Done</p>
        </div>
      </div>
      <div className="flex items-center my-4 p-4 bg-[#00000080]">
        <input
          className="flex-1 bg-transparent focus:outline-none text-medium leading-medium placeholder:text-default-tertiary text-white"
          placeholder="Search communities"
        />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <p className="text-small leading-small text-gray-1">
        Tag communities so your post can get more exposure to public and earn even more.
      </p>
      <div className="flex items-center justify-between px-1 py-4">
        <p className="text-medium leading-medium text-white">All communities</p>
        <Button
          label="Tag all"
          onClick={() =>
            setForm({
              ...form,
              communityIds: form.communityIds?.length === communityList?.length ? [] : [...communityList],
            })
          }
        />
      </div>
      {loading ? (
        <div className="flex items-center w-full justify-center mt-10">
          <CircleLoading />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {communityList?.length > 0 &&
            communityList.map((item, idx) => (
              <CommunityItem
                active={isHaveCommunityItem(item)}
                onClick={() => {
                  isHaveCommunityItem(item)
                    ? setForm({ ...form, communityIds: form.communityIds.filter((_item) => _item.id !== item.id) })
                    : setForm({ ...form, communityIds: [...form.communityIds, item] });
                }}
                data={item}
                key={idx}
              />
            ))}
        </div>
      )}
    </Drawer>
  );
}
