import { SOCIAL_PROVIDER, TSocialAccount } from "@/types";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { FaCheckCircle, FaLink, FaPlus } from "react-icons/fa";
import EmailPopup from "./EmailPopup";
import SocialSetting from "./SocialSetting";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

type SocialCardProps = {
  type: SOCIAL_PROVIDER;
  name?: string;
  icon: string;
  info?: TSocialAccount;
};

function SocialCard({ type, name, icon, info }: SocialCardProps) {
  const WebApp = useWebApp();
  const [open, setOpen] = useState(false);
  const [, notificationOccurred] = useHapticFeedback();

  const handleOpenDisconnect = (info: TSocialAccount) => {
    if (info.provider === SOCIAL_PROVIDER.TELEGRAM) {
      return notificationOccurred("warning");
    }
    setOpen(true);
  };

  const handleConnectSocial = () => {
    if (type !== SOCIAL_PROVIDER.EMAIL) {
      WebApp.openLink(`${process.env.REACT_APP_API_URL}/auth/mini-app/${type.toLocaleLowerCase()}/create`, {
        try_instant_view: false,
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <div
      className={`flex justify-between w-full p-3 rounded-xl border border-[#444444] ${
        info ? "bg-main" : "border-dashed"
      }`}
    >
      <div className="flex items-center space-x-2">
        <img src={icon} alt="icon" className="w-7 h-7" />
        <p>{name}</p>
        {info && <FaCheckCircle className="text-[#88FF8B] w-4 h-4" />}
      </div>
      <div className="flex items-center justify-center">
        {info ? (
          <FaLink onClick={() => handleOpenDisconnect(info)} className="w-5 h-5" />
        ) : (
          <FaPlus onClick={handleConnectSocial} className="w-5 h-5" />
        )}
      </div>
      {info && <SocialSetting open={open} setOpen={setOpen} social={info} />}
      {!info && type === SOCIAL_PROVIDER.EMAIL && <EmailPopup open={open} setOpen={setOpen} />}
    </div>
  );
}

export default SocialCard;
