import { LoginService } from "@/services";
import { ApiResponse } from "@/services/config";
import { TUser } from "@/types";
import { ImmerStateCreator } from "./useStore";

export type UserSlice = {
  user: TUser | null;
  points: number;
  prePoints: number;
  fetchUser: () => Promise<ApiResponse<TUser>>;
  addPoints: (points: number) => void;
};

export const createUserSlice: ImmerStateCreator<UserSlice> = (set) => ({
  user: null,
  points: 0,
  prePoints: 0,
  fetchUser: async () => {
    const res = await LoginService.me();
    if (res.success) {
      set((state) => {
        state.user = res.metadata;
        state.prePoints = res.metadata.points;
        state.points = res.metadata.points;
      });
    } else {
      set((state) => {
        state.user = null;
      });
    }
    return res;
  },

  addPoints: (points: number) => {
    set((state) => {
      state.prePoints = state.points;
      state.points += points;
    });
  },
});
