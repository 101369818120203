import { TListWallet } from "@/types";
import { maskString } from "@/utils/common.utils";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { FaCheck, FaLink, FaRegCopy, FaRegEdit } from "react-icons/fa";
import { WalletSetting } from "./WalletSetting";

type WalletCardProps = {
  wallet: TListWallet;
};

export default function WalletCard({ wallet }: WalletCardProps) {
  const [notification, notificationOccurred] = useHapticFeedback();
  const [copy, setCopy] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  const handleCopy = async () => {
    setCopy(true);
    notificationOccurred("success");
    await navigator.clipboard.writeText(wallet.address);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const handleOpenSetting = (open: boolean) => {
    notification("light");
    setOpenSetting(open);
  };

  return (
    <div className="w-full border-[#444444] border-[1px] bg-transparent mt-2 p-4 rounded-xl flex items-center justify-between" key={wallet.id}>
      <div className="flex items-center space-x-3">
        <img className="w-6 h-5" src="./Images/Home/phantom_logo.png" alt="wallet logo" />
        <div>
          <div className="flex items-center space-x-2">
            <p>{wallet.name}</p>
            <FaRegEdit size={16} className="text-gray-500" />
          </div>
          <div className="flex items-center space-x-2">
            <p className="text-gray-500">{maskString(wallet.address)}</p>
            {copy ? (
              <FaCheck size={16} className="text-green-500" />
            ) : (
              <FaRegCopy onClick={handleCopy} size={16} className="text-gray-500" />
            )}
          </div>
        </div>
      </div>
      <FaLink size={25} className="text-white" onClick={() => handleOpenSetting(true)} />
      <WalletSetting open={openSetting} setOpen={setOpenSetting} wallet={wallet} />
    </div>
  );
}
