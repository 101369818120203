import { FRIEND_CONFIRM_STATUS, REF_TYPE } from "@/const/app.const";
import { FriendRefService, LoginService } from "@/services";

const confirmAndResetNewUser = async (telegramId: number, username: string) => {
  await LoginService.loginWithTelegram(String(telegramId), username || "ABC_XYZ");

  const res = await FriendRefService.confirmRef(telegramId);
  if (res.metadata) {
    const rf = {
      id: res.metadata.userId,
      type: res.metadata.type === "user" ? REF_TYPE.USER : REF_TYPE.PARTNER,
      status: FRIEND_CONFIRM_STATUS.PENDING,
    };
    localStorage.setItem("refFriend", JSON.stringify(rf));
  }
};

export { confirmAndResetNewUser };
