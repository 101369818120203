import { TListWallet, TSocialAccount, TUser, WALLET_NAME } from "@/types";
import API, { ApiResponse } from "../config";

interface UpdateUserInterface {
  name?: string;
  username?: string;
  points?: number;
  avatar?: string;
  address?: string;
}

type TUploadAvatar = ApiResponse<{ url: string; fileName: string }>;

type CreateWalletDto = {
  address: string;
  name: string;
  wallet_name: WALLET_NAME;
};

class CreateUserService {
  updateUser = async (data: UpdateUserInterface) => {
    return await API.PATCH<ApiResponse<TUser>>("/user", data);
  };

  getUser = async (userId: string) => {
    return await API.GET<ApiResponse<TUser>>(`/user/${userId}`);
  };

  uploadAvatar = async (data: FormData) => {
    return await API.UPLOAD<TUploadAvatar>("/upload", data);
  };

  createWallet = async (data: CreateWalletDto) => {
    return await API.PATCH<ApiResponse<TListWallet>>("/auth/connect-wallet", data);
  };

  disconnectWallet = async (walletId: string) => {
    return await API.DELETE<ApiResponse<TListWallet>>(`/auth/disconnect-wallet/${walletId}`);
  };

  linkSocialAccount = async (socialId: String) => {
    return await API.PATCH<ApiResponse<TSocialAccount>>(`/auth/social/link/${socialId}`);
  };

  linkMailAccount = async (email: String) => {
    return await API.POST<ApiResponse<TSocialAccount>>(`/auth/email/create/${email}`);
  };

  unlinkSocialAccount = async (socialId: String) => {
    return await API.DELETE<ApiResponse<TSocialAccount>>(`/auth/social/unlink/${socialId}`);
  };
}

const UserService = new CreateUserService();
export { UserService };
