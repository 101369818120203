import { Button, Card } from "@/components/common";
import { MissionService } from "@/services";
import { useStore } from "@/store/useStore";
import { MissionStatusEnum, MissionTypeEnum, TMissionUser } from "@/types";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { TreknLogoImg } from "public/Images";
import { useEffect, useState } from "react";
import { FaCheckCircle, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoadingClaim from "./LoadingClaim";

export function MissionCard({ mission }: { mission: TMissionUser }) {
  return (
    <Card className="flex items-center justify-between">
      <div className="flex space-x-3 items-center">
        <img src={mission.detail.icon} alt="mission" className="w-5 h-5" />
        <div>
          <h3 className="leading-medium font-medium">{mission.detail.name}</h3>
          <div className="mt-1 flex items-center space-x-2">
            {mission.detail.points > 0 ? (
              <>
                <img src={TreknLogoImg} alt="POINTS" className="w-4 h-4 border border-[#FFE248] rounded-full" />
                <p>+{mission.detail.points}</p>
              </>
            ) : (
              <p className="text-sm text-gray-200">{mission.detail.description}</p>
            )}
          </div>
        </div>
      </div>
      <ButtonAction mission={mission} />
    </Card>
  );
}

const ButtonAction = ({ mission }: { mission: TMissionUser }) => {
  const navigate = useNavigate();
  const [, notificationOccurred, selectionChanged] = useHapticFeedback();
  const [missionStatus, setMissionStatus] = useState<MissionStatusEnum>(mission.status);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isChecked, setChecked] = useState<boolean>(false);
  const [isCallingServer, setCallingServer] = useState<boolean>(false);
  const { addPoints, user, FriendList, fetchFriendList } = useStore();

  useEffect(() => {
    if (user) {
      fetchFriendList();
    }
  }, [user]);

  const handleCheckRedirectMission = async () => {
    setChecked(true);
  };

  const handleClaimRedirectMission = async () => {
    const res = await MissionService.claimMission(mission.id);
    if (res.success) {
      setMissionStatus(MissionStatusEnum.CLAIMED);
      selectionChanged();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      setLoading(false);
      notificationOccurred("error");
      alert("Something went wrong, please try again later!");
    }
  };

  const handleSuccessMission = async () => {
    setCallingServer(true);
    const res = await MissionService.successMission(mission.id);
    if (res.success) {
      addPoints(mission.detail.points);
      notificationOccurred("success");
      setMissionStatus(MissionStatusEnum.SUCCESS);
      setCallingServer(false);
    } else {
      setCallingServer(false);
      notificationOccurred("error");
      alert("Something went wrong, please try again later!");
    }
  };

  const handleCheckStatusMission = async () => {
    setLoading(true);
    const res = await MissionService.checkMissionStatus(mission.detail.id);
    if (res.success) {
      if (res.metadata.status) {
        const res = await MissionService.claimMission(mission.id);
        if (res.success) {
          setTimeout(() => {
            setLoading(false);
            setMissionStatus(MissionStatusEnum.CLAIMED);
            notificationOccurred("success");
          }, 2000);
        } else {
          setLoading(false);
          notificationOccurred("error");
          alert("Something went wrong, please try again later!");
        }
      } else {
        const message = res.metadata.errorMessage || "You have not completed the mission yet!";
        setLoading(false);
        notificationOccurred("error");
        if (window.confirm(message)) {
          if (res.metadata.urlRedirect) {
            navigate(res.metadata.urlRedirect);
          }
          return;
        } else {
          return;
        }
      }
    } else {
      notificationOccurred("error");
      setLoading(false);
      alert("Something went wrong, please try again later!");
      setLoading(false);
    }
  };

  // Daily mission
  if (mission.detail.type === MissionTypeEnum.DAILY) {
    switch (missionStatus) {
      case MissionStatusEnum.PENDING:
        return (
          <Button onClick={handleSuccessMission} size="sm" className="!bg-[#99FF48] rounded-xl ml-3">
            <span className="text-small leading-small text-black">Claim</span>
          </Button>
        );
      case MissionStatusEnum.SUCCESS:
        return <FaCheckCircle size={20} color="#99FF48" />;
      default:
        return <></>;
    }
    // Redirect mission
  } else if (mission.detail.type === MissionTypeEnum.REDIRECT) {
    switch (missionStatus) {
      case MissionStatusEnum.PENDING:
        if (isChecked) {
          return (
            <Button onClick={handleClaimRedirectMission} size="sm" className="!bg-secondary rounded-xl ml-3">
              <span className="text-small leading-small text-white">Check</span>
            </Button>
          );
        } else {
          return (
            <a href={mission.detail.linkRedirect} target="blank">
              <Button
                size="sm"
                className="!bg-secondary rounded-xl ml-3"
                onClick={handleCheckRedirectMission}
                icon={<FaChevronRight size={20} />}
              />
            </a>
          );
        }
      case MissionStatusEnum.CLAIMED:
        return (
          <LoadingClaim
            loading={isLoading}
            handleSuccessMission={handleSuccessMission}
            isCallingServer={isCallingServer}
          />
        );
      case MissionStatusEnum.SUCCESS:
        return <FaCheckCircle size={20} color="#99FF48" />;
    }

    // Referral mission
  } else if (mission.detail.type === MissionTypeEnum.REFERRAL) {
    const refFriendTotal = Number(mission.detail.name.split(" ")[2]);
    const refFriendNum = FriendList.length;
    switch (missionStatus) {
      case MissionStatusEnum.PENDING:
        if (refFriendNum >= refFriendTotal) {
          return (
            <Button onClick={handleSuccessMission} size="sm" className="!bg-[#99FF48] rounded-xl ml-3">
              <span className="text-small leading-small text-black">Claim</span>
            </Button>
          );
        } else {
          return (
            <div className="!bg-secondary rounded-xl ml-3 py-2 px-4">
              <span className="text-small leading-small text-white">
                {refFriendNum}/{refFriendTotal}
              </span>
            </div>
          );
        }
      case MissionStatusEnum.SUCCESS:
        return <FaCheckCircle size={20} color="#99FF48" />;
    }
  } else if (mission.detail.type === MissionTypeEnum.IN_APP) {
    return (
      <Button
        onClick={() => navigate(mission.detail.linkRedirect)}
        size="sm"
        icon={<FaChevronRight size={20} />}
        className="!bg-secondary rounded-xl ml-3"
      />
    );
    // Call server check mission status
  } else if (mission.detail.type === MissionTypeEnum.CALL_CHECK) {
    switch (missionStatus) {
      case MissionStatusEnum.PENDING:
        return (
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={handleCheckStatusMission}
            size="sm"
            className="!bg-secondary rounded-xl ml-3"
          >
            <span className="text-small leading-small text-white">Verify</span>
          </Button>
        );
      case MissionStatusEnum.CLAIMED:
        return (
          <Button onClick={handleSuccessMission} size="sm" className="!bg-[#99FF48] rounded-xl ml-3">
            <span className="text-small leading-small text-black">Claim</span>
          </Button>
        );
      case MissionStatusEnum.SUCCESS:
        return <FaCheckCircle size={20} color="#99FF48" />;
    }
  } else {
    return <></>;
  }
  return <></>;
};
