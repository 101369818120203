import { TFriend, TRef, TUser } from "@/types";
import { TParamReq } from "@/utils/api.utils";
import qs from "qs";
import API, { ApiResponse, TPagination } from "../config";

type GetReferralRes = ApiResponse<{
  data: TRef[];
  pagination: TPagination;
}>;

type GetFriendRes = ApiResponse<{
  data: TFriend[];
  pagination: TPagination;
}>;

class CreateFriendRefService {
  async getReferralList(userId: string, params: TParamReq) {
    const res = API.GET<GetReferralRes>(`/ref/${userId}?${qs.stringify(params)}`);
    return res;
  }

  async checkReferral(userId: string, refId: string) {
    return await API.GET<ApiResponse<TUser | null>>(`/ref/check-ref/user/${userId}/ref/${refId}`);
  }

  async createRef(userSendRef: string, userReceiveRef: string) {
    return await API.POST<ApiResponse<TRef>>("/ref", {
      userId: userSendRef,
      refId: userReceiveRef,
    });
  }

  async createRefWithPartner(partnerId: string) {
    return await API.POST<ApiResponse<TRef>>("/ref/partner", {
      partnerId,
    });
  }

  async getRefList(userId: string, params: TParamReq) {
    return await API.GET<GetReferralRes>(`/ref/${userId}?${qs.stringify(params)}`);
  }

  async makeFriend(friendId: string) {
    return await API.POST<ApiResponse<TRef>>("/friend", {
      friendId,
    });
  }

  async getFriendList(params: TParamReq) {
    return await API.GET<GetFriendRes>(`/friend?${qs.stringify(params)}`);
  }

  async confirmRef(telegramId: number) {
    return await API.POST<ApiResponse<TRef | null>>(`/ref/confirmed/${telegramId}`);
  }
}

const FriendRefService = new CreateFriendRefService();
export { FriendRefService };
