import { HOME } from "@/const/path.const";
import { Link } from "react-router-dom";

function CaptureBanner() {
  return (
    <div className="w-full rounded-3xl border-[1px] border-[#60F13B] bg-[#0d2009] mt-10 p-7 text-white text-center flex flex-col gap-3 justify-center items-center">
      <span className="font-bold">Capture a moment to earn</span>
      <span className="font-thin">Earn Trekn points for future benefits from our ecosystem apps</span>
      <Link to={HOME.MOMENTS} className="w-full">
        <button className="w-full px-6 py-3 rounded-full border-[1px] border-[#FFFFFF30]">See detail</button>
      </Link>
    </div>
  );
}

export default CaptureBanner;
