import { Card } from "@/components/common";
import { TCommunity } from "@/types/community.type";

export function CommunitiesCard({ item }: { item: TCommunity }) {
  return (
    <Card className="flex flex-col items-center">
      <img src={item.avatar} alt="NFT" className="w-24 h-24 rounded-xl" />
      <div className="mt-2 space-y-2 text-center">
        <h1 className="font-medium text-medium text-white h-12 line-clamp-2">{item.name}</h1>
        <p className="text-secondary text-label-medium">{item.members.length} members</p>
      </div>
    </Card>
  );
}
