import { BottomBar } from "@/components/common";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function HomeLayout() {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <main className="pb-28">
      <Outlet />
      <BottomBar onChange={(item) => handleNavigate(item.path)} />
    </main>
  );
}
