import { Avatar } from "@/components/common";
import { TPost } from "@/types";
import { CountTime } from "@/utils";
import dayjs from "dayjs";

export function SmallPost({ item, showName = true }: { item: TPost; showName?: boolean }) {
  return (
    <div key={item.id} className="w-[9.375rem] h-[10.625rem] inline-block relative overflow-hidden">
      <img src={item.image} alt="img" className="w-full h-full rounded-xl object-cover z-10" />
      <div
        style={{
          background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
        }}
        className="z-20 absolute w-full h-full top-0 left-0"
      />
      <div className="absolute bottom-3 left-3 z-30">
        {item.author && showName && (
          <div className="flex items-center space-x-1">
            <Avatar src={item.author?.avatar} alt={item.author_id} className="w-6 h-6" border />
            <p className="text-small truncate">{item.author.name}</p>
          </div>
        )}
        <p className="text-[12px] mt-1">{CountTime.formatTime(item.createdAt, dayjs().toISOString())}</p>
      </div>
    </div>
  );
}
