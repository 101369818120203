import { Button, TreknLogo } from "@/components/common";
import { ACCOUNT, AUTH } from "@/const/path.const";
import { LoginService } from "@/services";
import { useHapticFeedback, useInitData } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function LoginPage() {
  const navigate = useNavigate();
  // const generator = new AvatarGenerator();
  const [, notificationOccurred] = useHapticFeedback();
  const [initDataUnsafe] = useInitData();
  const [loading, setLoading] = useState(true);

  const handleLogin = async () => {
    if (!initDataUnsafe?.user?.id) {
      alert("For security reasons, we can't get your ID here. Please switch to the Telegram app or try on mobile.");
      return;
    }

    setLoading(true);
    notificationOccurred("success");
    const login = await LoginService.loginWithTelegram(
      String(initDataUnsafe?.user?.id),
      initDataUnsafe?.user?.username!,
      `${initDataUnsafe.user.first_name!} ${initDataUnsafe.user.last_name!}`,
      `https://api.multiavatar.com/${String(initDataUnsafe.user.id!)}.svg`,
    );

    if (login.success) {
      localStorage.setItem("token", JSON.stringify(login.metadata.access_token));
      if (login.metadata.is_newUser) {
        window.location.href = AUTH.ONBOARD.FINISH;
      } else {
        // navigate("/");
        window.location.href = ACCOUNT.ROOT;
      }
    } else {
      setLoading(false);
      notificationOccurred("error");
      alert(login.message);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <section className="px-5 w-full h-screen">
      <div className="flex items-center justify-center w-full h-1/2">
        <TreknLogo className={`${loading ? "translate-y-1/2" : "translate-y-0"}`} loading={loading} />
      </div>
      <div className={`${loading && "hidden"}`}>
        <div className="space-y-3 w-full px-8">
          <h1 className="text-onboard text-[28px] font-semibold text-center leading-10">Social on-chain reimagined</h1>
          <p className="text-center text-onboard/70 text-medium">
            Bringing social fun on-chain, where people can build the most vibrant communities on Solana.
          </p>
        </div>
        <Button onClick={handleLogin} className="w-full justify-center !bg-main mt-20">
          Get Started
        </Button>
      </div>
    </section>
  );
}
