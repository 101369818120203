import { create, StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createFriendRefSlice, FriendsRefSlice } from "./friendsRef.slice";
import { createHomePostsSlice, HomePostsSlice } from "./homePosts.slice";
import { createLeaderboardSlice, LeaderboardSlice } from "./leaderboard.slice";
import { createMissionSlice, MissionSlice } from "./mission.slice";
import { createPointsSlice, PointsSlice } from "./points.slice";
import { createUserSlice, UserSlice } from "./user.silce";
import { createTagSlice, TagSlice } from "./tag.slice";

type MyState = PointsSlice & UserSlice & HomePostsSlice & LeaderboardSlice & FriendsRefSlice & MissionSlice & TagSlice;

export type ImmerStateCreator<T> = StateCreator<T, [["zustand/immer", never], never], [], T>;

const useStore = create(
  immer<MyState>((...a) => ({
    ...createPointsSlice(...a),
    ...createUserSlice(...a),
    ...createHomePostsSlice(...a),
    ...createLeaderboardSlice(...a),
    ...createFriendRefSlice(...a),
    ...createMissionSlice(...a),
    ...createTagSlice(...a),
  })),
);

export { useStore };
