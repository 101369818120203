import { BackButton } from "@/components/common";
import { useState } from "react";
import UserLeaderboard from "../components/UserLeaderboard";
import CommunityLeaderboard from "../components/CommunityLeaderboard";

enum Tab {
  User = "user",
  Community = "community",
}

export function LeaderBoard() {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.User);

  const handleChangeTab = (tab: Tab) => {
    setSelectedTab(tab);
  };

  return (
    <section className="relative">
      <div className="fixed w-full bg-[#191716] top-0 p-4">
        <div className="flex w-full items-center justify-center space-x-3 relative">
          <BackButton className="absolute left-0" />
          <h1 className="text-center">Leaderboard</h1>
        </div>
        <div className="mt-8 w-full">
          <div className="flex items-center space-x-6">
            <h1
              onClick={() => handleChangeTab(Tab.User)}
              className={`text-heading-base font-bold text-[#FFFFFF80] ${selectedTab === Tab.User && "!text-white"}`}
            >
              Treker Rank
            </h1>
            <h1
              onClick={() => handleChangeTab(Tab.Community)}
              className={`text-heading-base font-bold text-[#FFFFFF80] ${
                selectedTab === Tab.Community && "!text-white"
              }`}
            >
              Community
            </h1>
          </div>
        </div>
      </div>
      <div className="mt-36">
        {selectedTab === Tab.User && <UserLeaderboard />}
        {selectedTab === Tab.Community && <CommunityLeaderboard />}
      </div>
    </section>
  );
}
