import { useCountUp } from "@/hooks/common-hooks";

interface CountUpProps extends React.ComponentPropsWithRef<"p"> {
  start: number;
  end: number;
  duration: number;
}

export default function CountUp({ start, end, duration, className }: CountUpProps) {
  const count = useCountUp({ start, end, duration });

  return <p className={className}>{count.toFixed(2)}</p>;
}
