import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function formatTime(postTime: string, currentTime: string) {
  const post = dayjs(postTime);
  const current = dayjs(currentTime);

  const diffInMinutes = current.diff(post, "minute");
  const diffInHours = current.diff(post, "hour");
  const diffInDays = current.diff(post, "day");

  if (diffInMinutes < 1) {
    return "now";
  } else if (diffInMinutes < 60) {
    return diffInMinutes + "m ago";
  } else if (diffInDays < 1) {
    return diffInHours + "h ago";
  } else if (diffInDays === 1) {
    return "Yesterday, " + post.format("hA");
  } else {
    return post.format("MMM D, YYYY hA");
  }
}

export { formatTime };
