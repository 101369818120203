import { PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { TPost } from "@/types";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { ButtonHeader, PostToday } from "../components";
import WebApp from "@twa-dev/sdk";
import CaptureBanner from "../components/CaptureBanner";

export function HomePage() {
  // const showPopup = useShowPopup();
  // const { walletAddress } = useConnectWalletContext();
  const [claimPost, setClaimPost] = useState<TPost[]>([]);
  const {
    fetchHomePosts,
    fetchMyMissionList,
    user,
  } = useStore();

  useEffect(() => {
    ReactGA.initialize("G-C85G9C75BC");
    // ReactGA.send({ hitType: "pageview", page: "/", title: "test metrics" });
    ReactGA.event({
      category: "App Engagement",
      action: "open_app",
      label: "App opened from home screen",
    });

    if (WebApp.initDataUnsafe.start_param?.split("_")[0] === "from") {
      ReactGA.event({
        category: "App Engagement",
        action: "open_from_notification",
        label: "Open from notification",
      });
    }

  }, [user]);

  const getClaimPost = async () => {
    const post = await PostService.getPostInDayAndUnclaim();
    setClaimPost(post.metadata);
  };

  useEffect(() => {
    fetchHomePosts();
    getClaimPost();
    fetchMyMissionList();
  }, []);

  return (
    <section className="relative w-full h-screen">
      <div
        className="absolute w-full h-screen"
        style={{ background: "linear-gradient(180deg, rgba(43,78,31,1) 0%, rgba(0,0,0,1) 10rem)" }}
      />
      <div className="absolute p-4 w-full h-screen overflow-auto">
        <ButtonHeader />
        <div className="pt-12">
          <PostToday post={claimPost} />
        </div>
        <CaptureBanner/>
      </div>
    </section>
  );
}
