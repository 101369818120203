import { TUser } from "@/types";
import API, { ApiResponse } from "../config";

type TOtpRes = ApiResponse<{ otp: string }>;

type TLoginRes = ApiResponse<{ access_token: string }>;

type TTelegramRes = ApiResponse<{ id: string; is_newUser: boolean; access_token: string }>;

export class CreateLoginService {
  async login(address: string) {
    const getOTP = await API.POST<TOtpRes>("/auth/check-user", { address });
    const otp = getOTP.metadata.otp;
    const login = await API.POST<TLoginRes>("/auth/sign-in", { address, otp, password: "abc_xyz" });
    return login;
  }

  async me() {
    return await API.GET<ApiResponse<TUser>>("/auth/me");
  }

  async loginWithTelegram(telegram_id: string, username?: string, name?: string, avatar?: string) {
    return await API.POST<TTelegramRes>("/auth/login-with-telegram", {
      telegram_id,
      username,
      name,
      avatar,
      any_thins: "abc_xyz",
    });
  }

  async createTwitter() {
    return await API.PATCH("/auth/twitter/create");
  }

  async createDiscord() {
    return await API.PATCH("/auth/discord/create")
  }
}

const LoginService = new CreateLoginService();

export { LoginService };
