import { Avatar } from "@/components/common";
import { useStore } from "@/store/useStore";
import { useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";

export function SettingUserInfo() {
  const { user, fetchUser } = useStore();

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);
  return (
    <div className="mt-4 bg-main rounded-xl p-4 flex items-center space-x-3">
      <Avatar src={user?.avatar} alt="avatar" className="w-16 h-16" />
      <div className="flex-1">
        <div className="text-xl">{user?.name}</div>
        <div className="text-secondary text-sm">{user?.username}</div>
      </div>
      <div className="w-11 h-11 flex items-center justify-center">
        <FaChevronRight size={20} className="text-white/70" />
      </div>
    </div>
  );
}
