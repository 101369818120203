import { Card, HorizontalScroll, LinearButton } from "@/components/common";
import CountUp from "@/components/common/CountUp/CountUp";
import { ClaimPostTypeEnum } from "@/const/app.const";
import { ACCOUNT, ROUTE } from "@/const/path.const";
import { useWindowSize } from "@/hooks/common-hooks";
import { CommunityService, PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { TCommunity, TPost } from "@/types";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { TreknLogoImg } from "public/Images";
import { useEffect, useMemo, useState } from "react";
import Confetti from "react-confetti";
import { FaHeart, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CountdownTimer } from "./CountdownTimer";

const POINTS_PER_CLAIM = 300;

export function PostToday({ post }: { post: TPost[] }) {
  const [, notificationOccurred] = useHapticFeedback();
  const { windowHeight, windowWidth } = useWindowSize();
  const {
    canClaim,
    setCanClaim,
    user,
    calculateTimeClaim,
    secondsToClaim,
    pointThisPost,
    postId,
    setPostId,
    addPoints,
    onClaim,
  } = useStore();
  const [myCommunity, setMyCommunity] = useState<TCommunity[]>([]);
  const [confettiOpen, setConfettiOpen] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(false);

  const getPostToClaim = async () => {
    const postToClaim = post?.find((item) => item.status === ClaimPostTypeEnum.UNCLAIM);
    if (postToClaim) {
      setPostId(postToClaim.id);
      await calculateTimeClaim(postToClaim.id);
    } else {
      onClaim();
    }
  };

  const getMyCommunity = async () => {
    const res = await CommunityService.getMyCommunity();
    if (res.success) {
      const listCommunity = res.metadata.flatMap((item) => item.community);
      setMyCommunity(listCommunity);
    }
  };

  const handleCanClaim = () => {
    setCanClaim(true);
  };

  const handleClaim = async () => {
    const res = await PostService.claimPost(postId!, user?.id!);
    if (res.success) {
      setConfettiOpen(true);
      setRecycleConfetti(true);
      notificationOccurred("success");
      addPoints(300);
      onClaim();

      setTimeout(() => {
        setRecycleConfetti(false);
      }, 2000);

      setTimeout(() => {
        setConfettiOpen(false);
      }, 4000);
    } else {
      notificationOccurred("error");
      alert("Something went wrong, please try again later!");
    }
  };

  const totalLike = useMemo(() => post?.reduce((acc, item) => acc + item?.reaction?.length, 0), [post]);
  const totalPoint = useMemo(
    () =>
      post?.reduce((acc, item) => {
        return acc + item.reaction?.reduce((acc, item) => acc + item?.points, 0);
      }, 0),
    [post],
  );

  useEffect(() => {
    getPostToClaim();
    getMyCommunity();
  }, [post, user]);

  return (
    <main>
      {confettiOpen && <Confetti width={windowWidth} height={windowHeight} recycle={recycleConfetti} />}
      {post?.length > 0 ? (
        <div className="flex flex-col justify-center items-center space-y-5">
          <div className="flex flex-col items-center justify-center space-x-4">
            <p className="text-gray-2 text-medium">Unclaimed points</p>
            <div className="flex justify-center items-center space-x-2 mt-3">
              <h1 className="text-3xl font-medium text-white block text-center">
                {pointThisPost && (
                  <CountUp
                    start={pointThisPost}
                    end={POINTS_PER_CLAIM + POINTS_PER_CLAIM * 0.3 * myCommunity.length}
                    duration={secondsToClaim}
                  />
                )}
              </h1>
              <img src={TreknLogoImg} alt="Trekn" className="w-6 h-6" />
            </div>
            {myCommunity.length > 0 && (
              <div className="w-36 py-1 mt-4 rounded-full bg-[#18510b] text-center">Boosted</div>
            )}
            {postId ? (
              canClaim ? (
                <LinearButton onClick={handleClaim} className="!px-20 mt-4">
                  Claim
                </LinearButton>
              ) : (
                <Card className="mt-4 flex items-center space-x-3">
                  <p>Claim in</p>
                  <CountdownTimer time={secondsToClaim} onFinish={handleCanClaim} />
                </Card>
              )
            ) : (
              <Link to={ROUTE.CAPTURE.TAKE}>
                <LinearButton className="items-center justify-between mt-7 rounded-xl !px-9">
                  Checkin to start
                </LinearButton>
              </Link>
            )}
          </div>
          <HorizontalScroll>
            {post.map((item) => (
              <div
                key={item.id}
                className="inline-block rounded-xl border-2 border-[#99e093] ml-2 overflow-hidden"
                style={{ boxShadow: "0px 0px 20px 0px rgba(153, 255, 72, 0.5)" }}
              >
                <img src={item.image} alt={item.image} className="w-[10.25rem] h-[13.63rem] object-cover" />
              </div>
            ))}

            <Link to={canClaim ? ROUTE.CAPTURE.TAKE : "/"}>
              <div className="inline-block rounded-xl border-2 border-[#444444] ml-2">
                <div className="w-[10.25rem] h-[13.63rem] flex items-center justify-center">
                  <FaPlus size={30} className="text-[#99FF48]/30" />
                </div>
              </div>
            </Link>
          </HorizontalScroll>
          <Card className="w-full space-x-3 text-brand-on_brand font-semibold flex items-center justify-center ">
            <div className="flex items-center space-x-2">
              <p>{totalLike}</p>
              <FaHeart size={20} className="text-[#5E5E5E]" />
            </div>
            <div className="flex items-center space-x-2">
              <h1 className="text-white">{totalPoint}</h1>
              <img src={TreknLogoImg} alt="Trekn" className="w-6 h-6" />
            </div>
          </Card>
        </div>
      ) : (
        <div className="h-[428px] flex flex-col justify-between">
          <div className="flex flex-col gap-3 justify-center items-center space-x-2 mt-3">
            <img src="./Images/Home/depass_logo.png" alt="depass" className="w-[149px] h-[44px]" />
            <div className="flex flex-col gap-2 justify-center items-center">
              <p className="text-sm text-[#757575]">Verify your on-chain membership</p>
              <p className="text-[#444444] text-sm">Powered by Trekn Labs</p>
            </div>
          </div>
          <div className="flex gap-2 justify-center items-center">
            <div className="w-20 h-20 rounded-full border-[1px] border-[#483C2B] flex justify-center items-center">
              <img src="./Images/Home/box_icon.png" alt="box" className="w-6 h-6 object-contain" />
            </div>
            <div className="w-[140px] h-[140px] rounded-full flex justify-center bg-gradient-to-b from-[#F0BD79] to-[#3D3D3D] items-center">
              <div className="w-[139px] h-[139px] bg-black rounded-full flex justify-center items-center">
                <img src="./Images/Home/key_icon.png" alt="box" className="w-6 h-6" />
              </div>
            </div>
            <div className="w-20 h-20 rounded-full border-[1px] border-[#483C2B] flex justify-center items-center">
              <img src="./Images/Home/scroll_icon.png" alt="box" className="w-6 h-6" />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <Link to={ACCOUNT.ROOT} className="flex flex-col justify-center items-center">
              <LinearButton className="items-center justify-center flex mt-7 rounded-full w-64 h-14 bg-gradient-to-r from-[#CFA150] to-[#B98F46] bg-[#342D26]">
                <div className="w-[254px] h-[55px] bg-gradient-to-b to-[#CD903B33] from-[#342D2633] bg-[#342D26] rounded-full text-[#F0BD79] text-center items-center justify-center flex">
                  <span>Verify here</span>
                </div>
              </LinearButton>
              <div className="w-[80%] h-2 bg-[#F0BD794F] blur-md"></div>
            </Link>
          </div>
        </div>
      )}
    </main>
  );
}
