import { useCountdownByDurationGetTime } from "@/hooks/common-hooks";

export function CountdownTimer({ onFinish, time }: { onFinish: () => void; time: number }) {
  const onFinishCountdown = () => {
    console.log("Finish countdown");
    onFinish();
  };
  const timer = useCountdownByDurationGetTime(time, onFinishCountdown);

  return (
    <div className="text-white/50 w-16">
      {timer?.hours}:{timer?.minutes}:{timer?.seconds}
    </div>
  );
}
