import { TreknBanner, TreknBanner2, TreknBanner3 } from "public/Images";

export function StepTwo() {
  return (
    <div>
      <div className="px-7">
        <h1 className="text-onboard text-base text-center font-semibold leading-9">Join with the top communities</h1>
        <p className="text-center text-onboard/70 mt-4">
          Join and compete with OG communities or unlock special perks by owning an NFT from Trekn's VIP partners
        </p>
      </div>
      <div className="mt-6 flex items-center justify-between px-4">
        <img src={TreknBanner} alt="TreknBanner" className="w-[87px] h-[150px] object-cover rounded-full" />
        <img src={TreknBanner2} alt="TreknBanner" className="w-[115px] h-[200px] object-cover rounded-full" />
        <img
          src={TreknBanner3}
          alt="TreknBanner"
          className="w-[119px] h-[232px] object-cover scale-x-[-1] rounded-full"
        />
      </div>
    </div>
  );
}
