import { Card, HorizontalScroll, SmallPost } from "@/components/common";
import { CommunityEnum } from "@/const/app.const";
import { EXPLORE, FRIEND } from "@/const/path.const";
import { FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export function FriendsPosts({ items }: { items?: any[] }) {
  return items && items.length > 0 ? (
    <div>
      <div className="flex items-center justify-between text-small py-2">
        <p className="text-gray-1">From my friends</p>
        <Link to={`${EXPLORE.COMMUNITIES}?type=${CommunityEnum.FRIENDS}`}>View all</Link>
      </div>
      <HorizontalScroll>
        {items?.map((item) => (
          <SmallPost key={item.id} item={item} />
        ))}
      </HorizontalScroll>
    </div>
  ) : (
    <Link to={FRIEND.ROOT} className="block">
      <Card>
        <div className="flex flex-col items-center text-small text-center">
          <FaUserPlus color="#99ff48" size={20} className="mb-3" />
          <h3 className="font-bold">Add friend to share moment</h3>
          <p className="text-[#8E8E93]">Share everyday moments with friends and make each day special.</p>
        </div>
      </Card>
    </Link>
  );
}
