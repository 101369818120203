import { TCommunity } from "@/types/community.type";
import { TParamReq } from "@/utils/api.utils";
import qs from "qs";
import API, { ApiResponse, TPagination } from "../config";
import { TUser } from "@/types";

type ResAllCommunity = ApiResponse<{
  data: TCommunity[];
  pagination: TPagination;
}>;

type ResCommunity = ApiResponse<TCommunity>;

type ResCommunityList = ApiResponse<TCommunity[]>;

type ResLeaderboardUser = ApiResponse<{
  top10: TUser[];
  myRank: number | null;
}>;

type ResMyCommunity = ApiResponse<
  {
    userId: string;
    community: TCommunity;
    createdAt: string;
    updatedAt: string;
  }[]
>;

class CreateCommunityService {
  async getAllCommunity(params: TParamReq) {
    return await API.GET<ResAllCommunity>(`/community?${qs.stringify(params)}`);
  }

  async getCommunity(id: string) {
    return await API.GET<ResCommunity>(`/community/${id}`);
  }

  async getCommunityByUserId(id: string) {
    const result = await API.GET<ResCommunityList>(`/community/user/${id}`);
    return result.metadata;
  }

  async checkFollowAndMember(id: string) {
    return await API.GET<ApiResponse<{ follow: boolean; member: boolean }>>(`/community/me/checkFollow-member/${id}`);
  }

  async followCommunity(id: string) {
    return await API.POST<ResCommunity>(`/community/follow/${id}`);
  }

  async unFollowCommunity(id: string) {
    return await API.POST<ResCommunity>(`/community/unFollow/${id}`);
  }

  async getLeaderboard() {
    return await API.GET<ApiResponse<TCommunity[]>>("/community/leaderboard");
  }

  async getMyCommunity() {
    return await API.GET<ResMyCommunity>("/community/me/member");
  }

  async getUserLeaderboard() {
    return await API.GET<ResLeaderboardUser>("/user/leaderboard");
  }

  async onBoardCommunity() {
    return await API.POST<ApiResponse<TCommunity[]>>("/community/onboard");
  }

  async hasSupperTeam(address: string) {
    return await API.GET<ApiResponse<{ is_member: boolean }>>(`/community/has-superteam/${address}`);
  }
}

const CommunityService = new CreateCommunityService();

export { CommunityService };
