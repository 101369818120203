import { Button } from "@/components/common";
import { HOME } from "@/const/path.const";
import { confirmAndResetNewUser } from "@/hooks";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { OnboardBG, TreknLogoImg } from "public/Images";

export function CongratsCreate() {
  const [initDataUnsafe] = useInitData();

  const handleConfirmRef = async () => {
    await confirmAndResetNewUser(initDataUnsafe?.user?.id!, initDataUnsafe?.user?.username!);
    window.location.href = HOME.ROOT;
  };

  return (
    <section className="w-full h-screen relative">
      <div
        className="absolute w-full h-full -z-10"
        style={{
          background: "linear-gradient(0deg, rgba(84,45,20,0.7) 0%, rgba(0,0,0,1) 30%)",
        }}
      />
      <img src={OnboardBG} alt="OnboardBG" className="absolute bottom-0 object-none h-[500px] -z-20" />
      <div className="z-20 text-white w-full h-full flex items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-3 px-16">
          <img src={TreknLogoImg} alt="LOGO" className="w-9 h-9" />
          <h1 className="text-base">+1000 points</h1>
          <p className="text-center text-gray-1">
            Congrats, OG! You’re one of the first on Trekn. Here’s a reward for our early crew—don’t lose it!
          </p>
        </div>
      </div>
      <div className="absolute w-full bottom-14 z-30 px-5">
        <Button onClick={handleConfirmRef} className="w-full justify-center !bg-main">
          Continue
        </Button>
      </div>
    </section>
  );
}
