import { CameraIcon } from "public/Images";

export function StepOne() {
  return (
    <div className="px-4 flex flex-col items-center justify-around h-full">
      <h1 className="text-onboard text-base text-center font-semibold leading-9">
        Kick off with the 1st earning season
      </h1>
      <div>
        <img src={CameraIcon} alt="CameraIcon" className="h-36 mt-11" />
      </div>
      <p className="text-center text-onboard/70">
        We call it “Keep it real” campaign - people capture daily moment and earn from their photos.
      </p>
      <p className="text-center px-20 text-onboard/70 text-[12px]">
        <span className="mt-16 block">This is our appreciation to our first believers!</span>
      </p>
    </div>
  );
}
