import { AUTH, ROUTE } from "@/const/path.const";
import { useStore } from "@/store/useStore";
import IpLookup from "@iplookup/country";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function MintBreakpoint() {
  const { user } = useStore();
  const [isDisable, setIsDisable] = useState(true);
  const [isAtSG, setIsAtSG] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLocation = async () => {
      let ip = "";
      await fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          ip = data.ip;
        });
      const location = await IpLookup(ip);
      if (location.country === "SG") {
        setIsAtSG(true);
        setIsDisable(false);
      }
    };
    checkLocation();
  }, []);

  return (
    <div className="px-3 py-4 bg-gradient-to-r from-[#510EAA] to-[#8B54FF] rounded-xl">
      <p className="text-[#FAF8FD] tracking-wider font-light text-center">Mint your Proof of Attendance</p>
      <div
        className={`mt-4 py-3 rounded-full w-full flex items-center justify-center ${
          isDisable ? "bg-[#16161666] pointer-events-none" : "bg-[#161616]"
        }`}
        onClick={() => {
          if (!user?.list_wallet || user.list_wallet.length === 0) {
            navigate(AUTH.WALLET_OPTIONS.CONNECT);
          } else {
            navigate(`${ROUTE.CAPTURE.TAKE}?breakpoint=true`);
          }
        }}
      >
        <p className={`text-small leading-small ${isDisable ? "text-[#FFFFFFB2]" : "text-white"}`}>
          {!isAtSG
            ? "Only available in SG"
            : !user?.list_wallet || user.list_wallet.length === 0
            ? "Connect wallet to mint"
            : "Mint to get a WL chance"}
        </p>
      </div>
    </div>
  );
}
