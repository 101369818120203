import { TreknLogo } from "@/components/common";
import { useAuth, useCheckin, useTeleStartParam } from "@/hooks";
import { Outlet } from "react-router-dom";
import { ConnectWalletProvider } from "./ConnectWalletContext";
import { SocialContextProvider } from "./SocialContext";

export const ContextWrapper = () => {
  useAuth();
  useTeleStartParam();
  const { loading: checkinLoading } = useCheckin();

  if (checkinLoading) {
    return (
      <section className="px-5 w-full h-screen">
        <div className="flex items-center justify-center w-full h-1/2">
          <TreknLogo className={`${checkinLoading ? "translate-y-1/2" : "translate-y-0"}`} loading={checkinLoading} />
        </div>
      </section>
    );
  }

  return (
    <SocialContextProvider>
      <ConnectWalletProvider>
        <Outlet />
      </ConnectWalletProvider>
    </SocialContextProvider>
  );
};
