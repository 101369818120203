import { Avatar } from "@/components/common";
import { FaCrown } from "react-icons/fa";

export function LeaderBoardCard({
  name,
  icon,
  points,
  index,
}: {
  icon?: string;
  name?: string;
  points: number;
  index: number;
}) {
  return (
    <div className="px-4 py-3 bg-main flex items-center rounded-xl">
      <div className="flex items-center flex-1">
        <p className="mr-3">{index === 1 ? <FaCrown className="text-[#FFD80B]/85" size={16} /> : index}</p>
        <div className="flex items-center space-x-2">
          <Avatar src={icon} type="square" className="w-8 h-8" />
          <p className="truncate w-48">{name}</p>
        </div>
      </div>
      <p className="text-secondary">{points} points</p>
    </div>
  );
}
