import { HomePage, MissionPage, MyPointsPage } from "@/app/Home";
import { HomeLayout } from "@/app/layout";
import { ACCOUNT, AUTH, EVENT, EXPLORE, FRIEND, HOME, ROUTE } from "@/const/path.const";
import { createBrowserRouter } from "react-router-dom";
import { AccountPage } from "./app/Account";
import BreakpointAward from "./app/Breakpoint/BreakpointAward";
import CaptureFinish from "./app/Capture/Finish/CaptureFinish";
import CaptureTake from "./app/Capture/Take/CaptureTake";
import CheckinPage from "./app/Checkin/CheckinPage";
import { CommunityPage } from "./app/Community";
import Connection from "./app/Connection/Connection";
import { ConnectWallet, CreateWallet, WalletOptions } from "./app/ConnectWallet";
import { ExplorePage, LeaderBoard } from "./app/Explore";
import { FanpagePage } from "./app/Fanpage";
import BreakpointPage from "./app/Home/Breakpoint/BreakpointPage";
import { LoginPage } from "./app/Login";
import { OnboardCheckPage } from "./app/Onboarding/Check";
import { OnboardFinishPage } from "./app/Onboarding/Finish";
import { SettingPage, UpdateInfo } from "./app/Setting";
import { ContextWrapper } from "./context";
import { CaptureProvider } from "./context/CaptureContext";
import Moment from "./app/Home/components/Moments";

const router = createBrowserRouter([
  {
    element: <ContextWrapper />,
    children: [
      {
        path: HOME.ROOT,
        element: <HomeLayout />,
        children: [
          { path: HOME.ROOT, element: <HomePage /> },
          { path: HOME.MY_POINTS, element: <MyPointsPage /> },
          { path: HOME.MISSION, element: <MissionPage /> },
          { path: HOME.MOMENTS, element: <Moment/> },
          { path: EXPLORE.COMMUNITIES, element: <CommunityPage /> },
          { path: EXPLORE.ROOT, element: <ExplorePage /> },
          { path: EXPLORE.LEADER_BOARD, element: <LeaderBoard /> },
          { path: `${EXPLORE.FANPAGE}/:fanpageId`, element: <FanpagePage /> },
          { path: ACCOUNT.ROOT, element: <AccountPage /> },
          { path: ACCOUNT.SETTING, element: <SettingPage /> },
          { path: FRIEND.ROOT, element: <Connection /> },
          { path: ACCOUNT.UPDATE_INFO, element: <UpdateInfo /> },
          { path: EVENT.BREAKPOINT, element: <BreakpointPage /> },
        ],
      },
      {
        path: AUTH.LOGIN,
        element: <LoginPage />,
      },
      { path: AUTH.WALLET_OPTIONS.CONNECT, element: <WalletOptions /> },
      { path: AUTH.WALLET_OPTIONS.CREATE, element: <CreateWallet /> },
      {
        path: AUTH.ONBOARD.CHECK,
        element: <OnboardCheckPage />,
      },
      {
        path: AUTH.ONBOARD.FINISH,
        element: <OnboardFinishPage />,
      },
      {
        path: ROUTE.CONNECT_WALLET + "/:telegramId",
        element: <ConnectWallet />,
      },
      { path: ROUTE.CHECKIN, element: <CheckinPage /> },
      {
        element: <CaptureProvider />,
        children: [
          { path: ROUTE.CAPTURE.TAKE, element: <CaptureTake /> },
          { path: ROUTE.CAPTURE.FINISH, element: <CaptureFinish /> },
        ],
      },
      { path: EVENT.BREAKNPOINT_AWARD, element: <BreakpointAward /> },
    ],
  },
]);

export default router;
