import React from "react";
import { LoadingSpinner } from "../LoadingSpinner";

interface LinearButtonProps extends React.ComponentPropsWithRef<"button"> {
  children?: React.ReactNode;
  loading?: boolean;
}

export function LinearButton({
  children,
  className,
  style,
  onClick,
  loading = false,
  disabled = false,
}: LinearButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${className} text-medium transition-opacity duration-300 ${
        disabled && "opacity-50 cursor-not-allowed"
      }`}
      style={{...style }}
    >
      {loading && <LoadingSpinner className="mr-2" />}
      {children}
    </button>
  );
}
