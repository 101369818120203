import { BackButton, Button, CircleLoading, Input } from "@/components/common";
import { ACCOUNT } from "@/const/path.const";
import { UserService } from "@/services/user/user.service";
import { useStore } from "@/store/useStore";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AvatarInput } from "./components";

export function UpdateInfo() {
  const [, notificationOccurred] = useHapticFeedback();
  const navigate = useNavigate();
  const { user, fetchUser } = useStore();
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);

  const handleUpdate = async () => {
    const data = {
      avatar,
      name: username,
    };

    const filteredData = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null));

    const res = await UserService.updateUser(filteredData);
    if (res.success) {
      notificationOccurred("success");
      fetchUser();
      navigate(ACCOUNT.ROOT);
    } else {
      notificationOccurred("error");
      alert("Something went wrong, please try again later!");
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!user) {
      fetchUser();
    }
    // filterSocial();
    setLoading(false);
  }, [user]);
  return (
    <section className="p-4">
      <div className="flex items-center relative">
        <BackButton />
        <h1 className="absolute -translate-x-1/2 left-1/2">Edit Profile</h1>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-5">
          <CircleLoading />
        </div>
      )}
      {user && (
        <>
          <div className="flex items-center justify-center flex-col mt-8">
            <AvatarInput onChange={(url) => setAvatar(url)} defaultImage={user.avatar} />
            <p className="mt-2">{user?.name}</p>
            <p className="text-gray-500">@{user?.username}</p>
          </div>
          <Input label="Username" defaultValue={user.name!} onChange={(e) => setUsername(e.target.value)} />
          <Button onClick={handleUpdate} className="w-full mt-4 justify-center">
            Update
          </Button>
        </>
      )}
    </section>
  );
}
