import { LoginService } from "@/services";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import "@solana/wallet-adapter-react-ui/styles.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircleLoading } from "../CircleLoading";

export default function ConnectButton() {
  const { telegramId } = useParams<{ telegramId: string }>();
  const { publicKey, connect, wallet } = useWallet();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!publicKey && wallet) {
      connect();
    }
    if (publicKey) {
      (async () => {
        setLoading(true);
        if (telegramId) {
          const login = await LoginService.loginWithTelegram(telegramId);
          if (login.metadata.access_token) {
            const updateWallet = await fetch(`${process.env.REACT_APP_API_URL}/auth/connect-wallet`, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${login.metadata.access_token}`,
              },
              body: JSON.stringify({ address: publicKey.toBase58(), wallet_name: "PHANTOM" }),
            });
            if (!updateWallet.ok) {
              alert("some thing when wrong! Will retry");
              return (window.location.href = `${process.env.REACT_APP_TELEGRAM_PRODUCT_URL}`);
            }
          }
          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_TELEGRAM_PRODUCT_URL}?startapp=w_${publicKey}`;
          }, 2300);
        } else {
          alert("some thing when wrong! Will retry");
          return (window.location.href = `${process.env.REACT_APP_TELEGRAM_PRODUCT_URL}`);
        }
      })();
    }
  }, [publicKey]);

  return (
    <>{loading ? <CircleLoading /> : <WalletMultiButton style={{ backgroundColor: "#2C2C2C", color: "#99FF48" }} />}</>
  );
}
