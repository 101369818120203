import { SOCIAL_PROVIDER } from "@/types";

export const KEY_TOKEN = "token";
export const COOKIE_EXPIRED_DATE = 7;
export const KEY_API_RESPONSE = "api-response";

export const NOT_HAVE_VALUE_LABEL = "- -";
export const NOT_AVAILABLE_VALUE = "N/A";

export const DEBOUNCE_TIME_IN_MILLISECOND = 500;

export const SIZE_PAGINATION_DEFAULT = 12;
export const DEFAULT_PAGINATION = {
  page: 1,
  size: SIZE_PAGINATION_DEFAULT,
};
export const SORT_DIRECTION = {
  asc: 1,
  desc: -1,
};

export const BREAK_POINTS = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1440,
};

export const USD_FORMAT = {
  style: "currency",
  currency: "USD",
};

export const SOLANA_PROVIDER = "solana-provider";

export const INIT_PRICE_FEED = [0, 0, 0, 0];
export const INIT_BALANCES = [0, 0, 0, 0];

export const DISPLAY_ACTIVE_LOAN_BEST_OFFER = 5;

export const THREE_HOURS = 3 * 60 * 60;

export enum CommunityEnum {
  PUBLIC = "public",
  ME = "me",
  COMMUNITY = "community",
  FRIENDS = "friends",
}

export enum ClaimPostTypeEnum {
  UNCLAIM = "UNCLAIM",
  CLAIMED = "CLAIMED",
  SKIP = "SKIP",
}

export enum FRIEND_CONFIRM_STATUS {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum REF_TYPE {
  USER = "user",
  PARTNER = "partner",
}

export const CONST_SOCIALS = [
  // {
  //   name: SOCIAL_PROVIDER.EMAIL,
  //   icon: "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/email_logo.png",
  // },
  {
    name: SOCIAL_PROVIDER.DISCORD,
    icon: "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/discord_logo.png",
  },
  {
    name: SOCIAL_PROVIDER.X,
    icon: "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/twitter_logo.png",
  },
];
