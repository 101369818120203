import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { ReactNode, createContext, useContext, useMemo } from "react";
require("@solana/wallet-adapter-react-ui/styles.css");

const ConnectWalletContext = createContext({});
export const useConnectWalletContext = () => useContext(ConnectWalletContext);

export const ConnectWalletProvider = ({ children }: { children: ReactNode }) => {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl("devnet"), [network]);

  return (
    <ConnectWalletContext.Provider value={{}}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={[]}>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ConnectWalletContext.Provider>
  );
};
