/* eslint-disable no-empty-pattern */
import { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

interface CaptureContextInterface {
  form: CaptureFormInterface;
  setForm: (form: CaptureFormInterface) => void;
}

export interface CaptureFormInterface {
  content: string;
  nft: any;
  communityIds: Array<any>;
  image: string;
}
export const initCaptureForm = {
  content: "",
  nft: "",
  communityIds: [],
  image: "",
};

const CaptureContext = createContext<CaptureContextInterface>({ form: initCaptureForm, setForm: ({}) => {} });
export const useCaptureContext = () => useContext(CaptureContext);

export const CaptureProvider = () => {
  const [form, setForm] = useState<CaptureFormInterface>(initCaptureForm);
  return (
    <CaptureContext.Provider value={{ form, setForm }}>
      <Outlet />
    </CaptureContext.Provider>
  );
};
