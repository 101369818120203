import { deviceCheck } from "@/utils/common.utils";
import { useEffect, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import { Sheet, SheetRef } from "react-modal-sheet";

export function BottomSheet({
  open,
  onClose,
  showHeader = false,
  children,
  styles,
  className,
  height,
  snapTo = 0,
  initSnap = 0,
  closeIcon = true,
}: {
  open: boolean;
  onClose: () => void;
  showHeader?: boolean;
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
  height?: number[];
  snapTo?: number;
  initSnap?: number;
  closeIcon?: boolean;
}) {
  const ref = useRef<SheetRef>();
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    ref.current?.snapTo(snapTo);
  }, [snapTo]);

  return (
    <Sheet
      disableDrag
      ref={ref}
      initialSnap={initSnap}
      snapPoints={height || [300]}
      isOpen={open}
      onClose={handleClose}
    >
      <Sheet.Container style={{ ...styles, paddingBottom: ref.current?.y }} className={`!bg-main ${className}`}>
        {showHeader && <Sheet.Header />}
        <Sheet.Content>
          {closeIcon && (
            <div className="flex items-center justify-end px-4 pt-2">
              <IoIosClose onClick={handleClose} size={35} className="text-gray-1" />
            </div>
          )}
          {children}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
}
