import { HOME, ROUTE } from "@/const/path.const";
import { CheckinService } from "@/services";
import { useStore } from "@/store/useStore";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useCheckin() {
  const { user } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const hasCheckedRef = useRef(false);

  useEffect(() => {
    const checkCheckinStatus = async () => {
      if (user?.id && localStorage.getItem("token") && location.pathname === HOME.ROOT && !hasCheckedRef.current) {
        try {
          const response = await CheckinService.checkIsCheckedIn(user.id);
          const isChecked = response.metadata.checked;
          if (user.list_wallet.length !== 0 && !isChecked && response.metadata.data?.day !== 12) {
            hasCheckedRef.current = true;
            // navigate(ROUTE.CHECKIN);
          }
        } catch (error) {
          console.error("Error checking check-in status:", error);
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    checkCheckinStatus();
  }, [user, location, navigate]);

  return { loading };
}
