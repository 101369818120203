export enum WALLET_NAME {
  CREATE = "CREATE",
  PHANTOM = "PHANTOM",
  BACKPACK = "BACKPACK",
  BITGET = "BITGET",
}

export enum WALLET_IMAGE {
  CREATE = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/Trekn_icon_wallet.png",
  PHANTOM = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/Phantom_icon_wallet.png",
  BACKPACK = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/Trekn_icon_wallet.png",
  BITGET = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/Trekn_icon_wallet.png",
}

export enum SOCIAL_IMAGE {
  DISCORD = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/discord_logo.png",
  X = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/x_logo.png",
  TELEGRAM = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/telegram_logo.png",
}

export enum SOCIAL_PROVIDER {
  DISCORD = "DISCORD",
  TELEGRAM = "TELEGRAM",
  X = "X",
  EMAIL = "EMAIL",
}

export type TListWallet = {
  id: string;
  userId: string;
  name: string;
  address: string;
  walletName: WALLET_NAME;
  createdAt: string;
  updatedAt: string;
};

export type TSocialAccount = {
  id: string;
  userId: string;
  provider: SOCIAL_PROVIDER;
  socialId: string;
  username?: string;
  avatar?: string;
  createdAt: string;
  updatedAt: string;
};

export type TUser = {
  id: string;
  telegram_id: string;
  is_newUser: boolean;
  address: any;
  points: number;
  name?: string;
  username?: string;
  avatar?: string;
  list_wallet: TListWallet[];
  socialAccounts: TSocialAccount[];
  is_blocked: boolean;
  is_deleted: boolean;
  createdAt: string;
  updatedAt: string;
};
