import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function BackButton({ className, styles }: { className?: string; styles?: React.CSSProperties }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <button style={styles} className={`w-11 h-11 flex items-center justify-center ${className}`} onClick={goBack}>
      <FaChevronLeft size={20} className="text-white/70" />
    </button>
  );
}
