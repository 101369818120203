export const HOME = {
  ROOT: "/",
  MY_POINTS: "/my-points",
  MISSION: "/mission",
  MOMENTS: "/moments"
};
export const EXPLORE = {
  ROOT: "/explore",
  LEADER_BOARD: "/explore/leader-board",
  COMMUNITIES: "/explore/communities",
  FANPAGE: "/explore/fanpage",
};
export const ACCOUNT = {
  ROOT: "/account",
  SETTING: "/account/setting",
  UPDATE_INFO: "/account/setting/update-info",
};
export const AUTH = {
  LOGIN: "/login",
  WALLET_OPTIONS: {
    CONNECT: "/wallet-options",
    CREATE: "/wallet-options/create",
  },
  ONBOARD: {
    CHECK: "/onboard/check",
    FINISH: "/onboard/finish",
  },
};

export const FRIEND = {
  ROOT: "/friend",
};

export const EVENT = {
  BREAKPOINT: "/breakpoint",
  BREAKNPOINT_AWARD: "/breakpoint/award",
};

export const ROUTE = {
  CONNECT_WALLET: "/connect-wallet",
  DESKTOP_PHANTOM: `${process.env.REACT_APP_PRODUCT_URL}/connect-wallet/`,
  CONNECT_PHANTOM: `https://phantom.app/ul/browse/${process.env.REACT_APP_PRODUCT_URL}/connect-wallet/`,
  CONNECT_BITGET: `https://bkcode.vip?action=dapp&_needChain=sol&url=${process.env.REACT_APP_PRODUCT_URL}/connect-wallet/`,
  CAPTURE: {
    TAKE: "/capture/take",
    FINISH: "/capture/finish",
  },
  CHECKIN: "/checkin",
};

export const TWITTER_URL = "https://twitter.com/Ensofi_xyz";
export const SUPPORT_LINK = "#";
export const DOCUMENT_LINK = "https://enso-finance.gitbook.io/enso-finance";
export const DISCORD_LINK = "https://discord.com/invite/HXbUK4qfKd";
export const BLOG_LINK = "https://mirror.xyz/0x6c97fDa263bda87dec97d43BCc21356197E011ae";
