/* eslint-disable no-empty-pattern */
import { useCaptureContext } from "@/context/CaptureContext";
import { compressImageFile } from "@/utils/file.utils";
import { forwardRef } from "react";
import ReactGA from "react-ga4";

const CaptureCamera = forwardRef<HTMLInputElement>(({}, ref) => {
  const { form, setForm } = useCaptureContext();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    ReactGA.event({
      category: "Button Clicks",
      action: "take_photo",
      label: "Take photo",
    });
    const file = event.target.files?.[0];
    if (file) {
      const compressFile = await compressImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString();
        if (base64String) {
          setForm({ ...form, image: base64String });
        }
      };
      reader.readAsDataURL(compressFile);
    }
  };

  return (
    <div className="relative flex items-center justify-center h-full">
      <p className="font-bold text-icon opacity-50 text-[72px]">+</p>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        className="absolute cursor-pointer opacity-0 left-0 right-0 top-0 bottom-0"
        onChange={handleFileChange}
        ref={ref}
      />
    </div>
  );
});

export default CaptureCamera;
