import CryptoJS, { AES } from "crypto-js";

export const decryptData = (data: string) => {
  const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  if (!ENCRYPT_KEY) {
    throw new Error("Missing ENV encryption key");
  }
  const bytes = AES.decrypt(data, ENCRYPT_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encryptData = (data: string) => {
  const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  if (!ENCRYPT_KEY) {
    throw new Error("Missing ENV encryption key");
  }
  return AES.encrypt(data, ENCRYPT_KEY).toString();
};
