import { Avatar, BackButton, CircleLoading, HorizontalScroll, InfiniteScroll } from "@/components/common";
import { CommunityEnum } from "@/const/app.const";
import { CommunityService, PostService } from "@/services";
import { TPost, TTag } from "@/types";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Post } from "../components";

const TAG_ALL_ID = "0x000";

export function CommunityPage() {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [CommunityAvatar, setCommunityAvatar] = useState<string | null>(null);
  const [posts, setPosts] = useState<TPost[]>([]);
  const [tags, setTags] = useState<TTag[]>([]);
  const [selectTag, setSelectTag] = useState<string>(TAG_ALL_ID);

  const type: CommunityEnum = useMemo(() => (searchParams.get("type") as CommunityEnum) || "", [searchParams]);
  const communityId: string | null = useMemo(() => searchParams.get("communityId"), [searchParams]);

  const generateQuery = () => {
    if (selectTag !== TAG_ALL_ID && type !== CommunityEnum.COMMUNITY) {
      return {
        page,
        limit: 10,
        tags: {
          some: {
            tagId: {
              in: [selectTag],
            },
          },
        },
      };
    } else if (selectTag !== TAG_ALL_ID && type === CommunityEnum.COMMUNITY) {
      return {
        page,
        limit: 10,
        post: {
          tags: {
            some: {
              tagId: {
                in: [selectTag],
              },
            },
          },
        },
      };
    } else {
      return {
        page,
        limit: 10,
      };
    }
  };

  const getTitle = async () => {
    switch (type) {
      case CommunityEnum.PUBLIC:
        setTitle("Public");
        break;
      case CommunityEnum.ME:
        setTitle("My Posts");
        break;
      case CommunityEnum.COMMUNITY:
        const community = await CommunityService.getCommunity(communityId as string);
        setCommunityAvatar(community.metadata.avatar);
        setTitle(community.metadata.name);
        break;
      case CommunityEnum.FRIENDS:
        setTitle("Friends");
        break;
      default:
        break;
    }
  };

  const fetchPosts = async () => {
    switch (type) {
      case CommunityEnum.PUBLIC:
        const posts = await PostService.getAllPost(generateQuery());
        setTotalItem(posts.metadata.pagination.total);
        setPosts((pre) => [...pre, ...posts.metadata.data]);
        break;
      case CommunityEnum.ME:
        const myPosts = await PostService.getMyPost(generateQuery());
        setTotalItem(myPosts.metadata.pagination.total);
        setPosts((pre) => [...pre, ...myPosts.metadata.data]);
        break;
      case CommunityEnum.COMMUNITY:
        const communityPosts = await PostService.getPostByCommunityId(communityId as string, generateQuery());
        setTotalItem(communityPosts.metadata.pagination.total);
        setPosts((pre) => [...pre, ...communityPosts.metadata.data.flatMap((item) => item.post)]);
        break;
      case CommunityEnum.FRIENDS:
        const friendPosts = await PostService.getFriendPost(generateQuery());
        setTotalItem(friendPosts.metadata.pagination.total);
        setPosts((pre) => [...pre, ...friendPosts.metadata.data]);
        break;
      default:
        break;
    }
  };

  const fetchTags = async () => {
    const tagAll = { id: TAG_ALL_ID, name: "All" } as TTag;
    const tags = await PostService.fetchAllTags();
    if (tags.success) {
      setTags([tagAll, ...tags.metadata]);
    } else {
      alert(tags.message);
    }
  };

  const handleSelectTag = (tagId: string) => {
    setSelectTag(tagId);
    setPage(1);
    setPosts([]);
  };

  useEffect(() => {
    getTitle();
  }, [searchParams]);

  useEffect(() => {
    fetchPosts();
  }, [page, selectTag]);

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <section className="p-5">
      <div className="flex items-center justify-center relative mt-3">
        <BackButton className="absolute left-0" />
        <div className="flex items-center space-x-2">
          {type === CommunityEnum.COMMUNITY && CommunityAvatar && <Avatar src={CommunityAvatar} className="w-7 h-7" />}
          <p className="text-medium font-semibold">{title}</p>
        </div>
      </div>
      <div>
        <HorizontalScroll className="my-6">
          {tags.map((item: TTag) => (
            <button
              onClick={() => handleSelectTag(item.id)}
              key={item.id}
              className={`px-3 py-2 min-w-16 bg-[#FFFFFF1A] text-white text-center rounded-full flex gap-2 ${
                selectTag === item.id && "border-2 border-[#99FF48]"
              }`}
            >
              <p className="w-full text-center">{item.name}</p>
            </button>
          ))}
        </HorizontalScroll>
      </div>
      <div className="mt-8">
        {posts?.length > 0 && (
          <InfiniteScroll
            loader={
              <div className="flex items-center justify-center w-full">
                <CircleLoading className="mt-8" />
              </div>
            }
            hasMore={posts.length < totalItem}
            fetchMore={() => setPage((pre) => pre + 1)}
          >
            {posts.map((post) => (
              <Post key={post.id} post={post} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </section>
  );
}
