import { CircleLoading } from "@/components/common";
import { AUTH, ROUTE } from "@/const/path.const";
import { PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HeaderButton, ListNFT, UserInfo } from "./components";
import { PhantomIcon } from "public/Images";
import ReactGA from "react-ga4";
import { FaPlus } from "react-icons/fa";
import { deviceCheck } from "@/utils/common.utils";
import { Button } from "@/components/common";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import WalletCard from "../Setting/components/WalletCard";
import { CONST_SOCIALS } from "@/const/app.const";
import { SOCIAL_PROVIDER, TSocialAccount } from "@/types";
import SocialCard from "../Setting/components/SocialCard";

type MySocial = {
  id: string;
  name: SOCIAL_PROVIDER;
  icon: string;
  info?: TSocialAccount;
};

const sortSocial = (a: MySocial, b: MySocial) => {
  if (a.info === undefined) return 1;
  if (b.info === undefined) return -1;
  return 0;
};

const TELEGRAM_ICON = "https://trekn-miniapp.s3.ap-southeast-1.amazonaws.com/be/trekn-miniapp/telegram_logo.png";

export function AccountPage() {
  const { user } = useStore();
  const [listNFT, setListNFT] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initDataUnsafe] = useInitData();
  const [mySocial, setMySocial] = useState<MySocial[] | null>(null);

  const filterSocial = () => {
    if (user) {
      const socials = user.socialAccounts;
      const mySocials = CONST_SOCIALS.map((key) => {
        const social = socials?.find((s) => s.provider === key.name);
        return {
          id: social?.id || key.name,
          name: key.name,
          icon: key.icon,
          info: social,
        };
      });
      mySocials.push({
        id: SOCIAL_PROVIDER.TELEGRAM,
        name: SOCIAL_PROVIDER.TELEGRAM,
        icon: TELEGRAM_ICON,
        info: {
          id: "",
          userId: "",
          provider: SOCIAL_PROVIDER.TELEGRAM,
          socialId: "",
          username: user.username,
          avatar: "",
          createdAt: "",
          updatedAt: "",
        },
      });
      setMySocial(mySocials.sort(sortSocial));
    }
  };

  useEffect(() => {
    filterSocial();
  }, [user]);

  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     if (user?.list_wallet) {
  //       const result = await PostService.getListNft(user.list_wallet[0].address);
  //       setListNFT(result.nfts);
  //     }
  //     filterSocial();
  //     setLoading(false);
  //   })();
  // }, [user]);
  return (
    <section className="p-4">
      <HeaderButton />
      <UserInfo />
      {/* <ListCommunity item={TagsList} /> */}
      {!user?.list_wallet ||
        (user.list_wallet.length === 0 && (
          <div className="flex flex-col items-center border border-dashed border-[#2C2C2C] mt-[50%] py-6 px-3">
            <p className="text-center text-small leading-small text-[#5A5A5A]">
              Connect wallet to sync your membership to Trekn
            </p>
            <Link
              to={AUTH.WALLET_OPTIONS.CONNECT}
              className="w-full bg-[#2C2C2C] flex items-center justify-center py-3 rounded-lg mt-5"
            >
              Connect Wallet
            </Link>
          </div>
        ))}
      {user && user.list_wallet.length > 0 ? (
        <div className="mt-6 space-y-3">
          <p>My Wallet:</p>
          {user.list_wallet.map((wallet) => (
            <WalletCard key={wallet.id} wallet={wallet} />
          ))}
        </div>
      ) : (
        <div className="space-y-4">
          <Link className="block" to={AUTH.WALLET_OPTIONS.CREATE}>
            <Button className="w-full !bg-main" icon={<FaPlus size={20} />}>
              Create new wallet
            </Button>
          </Link>
          <Link
            className="block"
            to={
              deviceCheck() === "desktop"
                ? `${ROUTE.DESKTOP_PHANTOM + initDataUnsafe?.user?.id}`
                : `${ROUTE.CONNECT_PHANTOM + initDataUnsafe?.user?.id + "?ref=trekn"}`
            }
            target="_blank"
            onClick={() => {
              ReactGA.event({
                category: "Button Clicks",
                action: "connect_phantom",
                label: "Connect phantom",
              });
            }}
          >
            <Button className="w-full !bg-main" icon={<img src={PhantomIcon} alt="Phantom Icon" className="w-5" />}>
              Phantom wallet
            </Button>
          </Link>
        </div>
      )}

      <div className="my-3 space-y-4">
        <p>Social:</p>
        {mySocial &&
          mySocial.length > 0 &&
          mySocial.map((social) => (
            <SocialCard
              key={social.name}
              type={social.name}
              name={social.info ? social.info.username : `Connect ${social.name}`}
              icon={social.icon}
              info={social.info}
            />
          ))}
      </div>

      {/* {loading ? (
        <div className=" h-[40%] flex items-center justify-center mt-5">
          <CircleLoading />
        </div>
      ) : (
        <ListNFT item={listNFT} />
      )} */}
    </section>
  );
}
