import { FaArchway, FaCompass, FaCrown, FaUser } from "react-icons/fa";
import { ACCOUNT, EXPLORE, HOME } from "./path.const";

export const NESTED_ROUTES = [
  { label: "Home", path: HOME.ROOT, icon: <FaArchway size={24} /> },
  {
    label: "Quests",
    path: HOME.MISSION,
    icon: (
      <span className="relative">
        <span className="absolute w-[6px] h-[6px] bg-[#99ff48] rounded-full -right-3 -top-1" />
        <FaCrown size={24} />
      </span>
    ),
  },
  { label: "Explore", path: EXPLORE.ROOT, icon: <FaCompass size={24} /> },
  { label: "Profile", path: ACCOUNT.ROOT, icon: <FaUser size={24} /> },
];
