import { deviceCheck } from "@/utils/common.utils";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { LuClipboard, LuClipboardCheck } from "react-icons/lu";

type RenderSeedPhraseProps = {
  seedPhrase: string | null;
};

export default function RenderSeedPhrase({ seedPhrase }: RenderSeedPhraseProps) {
  const [notificationHaptic, notificationOccurred] = useHapticFeedback();
  const seeds = seedPhrase?.split(" ") || [];
  const [show, setShow] = useState(false);
  const [copy, setCopy] = useState(false);

  const handleShowSeeds = () => {
    setShow(!show);
    notificationHaptic("light");
  };

  const handleCopySeeds = async () => {
    if (!seedPhrase) return;
    setCopy(true);
    notificationOccurred("success");
    await navigator.clipboard.writeText(seedPhrase);
  };

  return (
    <div className="w-full">
      <div className={`bg-main w-full py-4 px-8 rounded-lg ${!show && "blur-sm"}`}>
        {seeds.map((seed, index) => (
          <div key={index} className="flex items-center justify-between my-3">
            <p className="text-white">
              <span className="min-w-6 inline-block">{String(index + 1).padStart(2, "0")}</span> .
            </p>
            <p>------------</p>
            <p className="text-white min-w-20">{show ? seed : "****************"}</p>
          </div>
        ))}
        {deviceCheck() === "desktop" && <p>{seedPhrase}</p>}
      </div>
      <div className="mt-4 space-x-4 w-full flex items-center justify-center">
        <button onClick={handleShowSeeds} className="py-2 px-4 bg-main rounded-md space-x-2 flex items-center min-w-28">
          {show ? <FaRegEyeSlash size={24} className="inline" /> : <FaRegEye size={24} className="inline" />}
          <span className="text-white text-center block w-full">{show ? "Hide" : "Show"}</span>
        </button>
        {deviceCheck() === "mobile" && (
          <button
            onClick={handleCopySeeds}
            className="py-2 px-4 bg-main rounded-md space-x-2 flex items-center min-w-28"
          >
            {copy ? <LuClipboardCheck size={24} className="inline" /> : <LuClipboard size={24} className="inline" />}
            <span className="text-white text-center block w-full">{copy ? "Copied" : "Copy"}</span>
          </button>
        )}
      </div>
    </div>
  );
}
