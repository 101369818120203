import { Button } from "@/components/common";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { CongratsCreate, StepDotBar, StepOne, StepThree, StepTwo } from "../components";

enum OnboardStep {
  STEP0 = 0,
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
}

export function OnboardFinishPage() {
  const [, notificationOccurred] = useHapticFeedback();
  const [step, setStep] = useState(OnboardStep.STEP0);

  const nextStep = () => {
    setStep((prev) => prev + 1);
    notificationOccurred("success");
  };

  return (
    <main>
      <section className="w-full h-screen flex flex-col relative overflow-hidden">
        {step !== OnboardStep.STEP3 && <StepDotBar step={step} />}
        <div className="w-full flex-1">
          {step === OnboardStep.STEP0 && <StepOne />}
          {step === OnboardStep.STEP1 && <StepTwo />}
          {step === OnboardStep.STEP2 && <StepThree />}
          {step === OnboardStep.STEP3 && <CongratsCreate />}
        </div>
        <div className="h-[200px]" />
        {step !== OnboardStep.STEP3 && (
          <div className="px-5 w-full absolute bottom-16">
            <Button onClick={nextStep} className="w-full justify-center !bg-main">
              Continue
            </Button>
          </div>
        )}
      </section>
    </main>
  );
}
