import API, { ApiResponse } from "../config";

class CreateTimeService {
  async getTimeSever() {
    const res = await API.GET<ApiResponse<string>>("/time");
    return res.metadata;
  }
}

const TimeService = new CreateTimeService();
export { TimeService };
