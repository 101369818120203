import { AUTH } from "@/const/path.const";
import { UserService } from "@/services/user/user.service";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

enum StartParamType {
  CHECK_NFT_WALLET = "w",
  LINK_SOCIAL_ACCOUNT = "s",
}

const handleLinkSocialAccount = async (socialId: string) => {
  if (localStorage.getItem("token")) {
    await UserService.linkSocialAccount(socialId);
  }
};

const handleStartParam = async (type: StartParamType, data: string, navigate: any) => {
  switch (type) {
    case StartParamType.CHECK_NFT_WALLET:
      navigate(AUTH.ONBOARD.CHECK);
      break;
    case StartParamType.LINK_SOCIAL_ACCOUNT:
      await handleLinkSocialAccount(data);
      break;
    default:
      break;
  }
};

export const useTeleStartParam = () => {
  const [initDataUnsafe] = useInitData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!initDataUnsafe?.start_param) {
      return;
    }
    const param = initDataUnsafe?.start_param.split("_");
    handleStartParam(param[0] as StartParamType, param[1], navigate);
  }, []);
};
