import { BottomSheet, Button } from "@/components/common";
import { UserService } from "@/services/user/user.service";
import { useStore } from "@/store/useStore";
import { TSocialAccount } from "@/types";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import React, { useState } from "react";

type SocialSettingProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  social: TSocialAccount;
};

enum HeightPoint {
  DISCONNECT_WALLET = 0,
  EXPORT_PRIVATE_KEY = 0,
  DEFAULT = 1,
  CLOSE = 2,
}

function SocialSetting({ open, setOpen, social }: SocialSettingProps) {
  const { fetchUser } = useStore();
  const [, notificationOccurred] = useHapticFeedback();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlink = async () => {
    setLoading(true);
    const res = await UserService.unlinkSocialAccount(social.id);
    if (res.success) {
      notificationOccurred("success");
      fetchUser();
      setOpen(false);
      setLoading(false);
    } else {
      alert("Something went wrong! Please try again.");
      setLoading(false);
    }
  };
  return (
    <BottomSheet
      closeIcon={false}
      initSnap={HeightPoint.DEFAULT}
      snapTo={HeightPoint.DEFAULT}
      open={open}
      onClose={handleClose}
      height={[800, 400, 0]}
    >
      <div className="flex flex-col items-center justify-center space-y-2 mt-16">
        <p className="w-[80%] font-medium text-[16px] text-[#F5F5F5] text-center">
          Are you sure that you want to unlink this Discord connection?
        </p>
        <div className="flex gap-5 py-5 w-full px-3">
          <Button onClick={() => setOpen(false)} className="w-1/2 justify-center">
            Cancel
          </Button>
          <Button
            onClick={handleUnlink}
            disabled={loading}
            loading={loading}
            className="w-1/2 justify-center !bg-red-600"
          >
            Disconnect
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
}

export default SocialSetting;
