import { SolanaSupportedTokenEnum, SupportedChainEnum } from "@/models";
import WebApp from "@twa-dev/sdk";
import { DateUtils } from ".";

export const uuid = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .replace(/-/g, "");
};

export const checkEmailFormat = (email: string): boolean => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.length && !regexEmail.test(email)) return false;
  else return true;
};

export const snakeToCamelCase = (str: string): string => {
  if (str.includes("_") || str.includes("-"))
    return str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));

  return str;
};

export const isUndefinedOrNull = (value: any) => {
  return value === null || value === undefined;
};

export const truncateHash = (address?: string, startLength = 5, endLength = 5) => {
  if (!address) return "";
  return `${address.substring(0, startLength)}...${address.substring(address.length - endLength)}`;
};

export const deepEqual = (value1: any, value2: any) => {
  if (value1 === value2) return true;

  if (value1 === null || value2 === null || typeof value1 != "object" || typeof value2 != "object") return false;

  const value1Keys = Object.keys(value1);
  const value2Keys = Object.keys(value2);

  if (value1Keys.length !== value2Keys.length) return false;

  for (const key of value1Keys) {
    if (!value2Keys.includes(key) || !deepEqual(value1[key], value2[key])) return false;
  }

  return true;
};

export const getTransactionHashInfoLink = (chain: SupportedChainEnum, transactionHash: string) => {
  switch (chain) {
    case SupportedChainEnum.Solana:
      const params = process.env.NETWORK_MODE !== "mainnet" ? `?cluster=${process.env.NETWORK_MODE}` : "";
      return `${process.env.NEXT_PUBLIC_SOLS_EXPLORER_URL}/tx/${transactionHash}/${params}`;
    default:
      return "";
  }
};

export const calculateInterestValue = (amount: number, percent: number, durations: number, totalItems = 1) => {
  const durationRatio = durations / 365;
  const percentRatio = percent / 100;

  return amount * percentRatio * durationRatio * totalItems;
};

export const getTimeLabel = (
  getCommonLabel: any,
  endTime: number, // Timestamp
) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const timeRemain = Math.abs(endTime - currentTimestamp);

  const { day, hour, minute, month } = DateUtils.convertSecondsToDayHourMinute(timeRemain);

  if (month > 1) {
    return getCommonLabel("fmMonths", {
      count: month,
    });
  }

  if (day > 1) {
    return getCommonLabel("fmDays", {
      count: day,
    });
  }

  if (hour > 1) {
    return getCommonLabel("fmHours", {
      count: hour,
    });
  }

  return getCommonLabel("fmMinutes", {
    count: minute > 0 ? minute : 1,
  });
};

export const filterCurrentTokenBalance = (
  balances: number[],
  token: SolanaSupportedTokenEnum,
  selectedChain: SupportedChainEnum,
) => {
  if (!balances.length) return 0;

  if (selectedChain === SupportedChainEnum.Solana) {
    // index SOL_JTO_PYTH_USDC
    switch (token) {
      case SolanaSupportedTokenEnum.SOL:
        return balances[0];
      case SolanaSupportedTokenEnum.JTO:
        return balances[1];
      case SolanaSupportedTokenEnum.PYTH:
        return balances[2];
      case SolanaSupportedTokenEnum.USDC:
        return balances[3];
      default:
        return 0;
    }
  }

  // TODO: Update when implement other chain
  return 0;
};

export const getValueByToken = (
  selectedChain: SupportedChainEnum,
  selectedToken: SolanaSupportedTokenEnum,
  data: number[],
) => {
  if (!data.length) return 0;

  if (selectedChain === SupportedChainEnum.Solana) {
    // index SOL_JTO_PYTH_USDC
    switch (selectedToken) {
      case SolanaSupportedTokenEnum.SOL:
        return data[0];
      case SolanaSupportedTokenEnum.JTO:
        return data[1];
      case SolanaSupportedTokenEnum.PYTH:
        return data[2];
      case SolanaSupportedTokenEnum.USDC:
        return data[3];
      default:
        return 0;
    }
  }
  // TODO: Update when implement other chain
  return 0;
};

export const calculateTokensValue = (
  selectedChain: SupportedChainEnum,
  selectedToken: SolanaSupportedTokenEnum,
  amount: number,
  rates: number[],
) => {
  if (!rates.length) return 0;

  if (selectedChain === SupportedChainEnum.Solana) {
    // index SOL_JTO_PYTH_USDC
    switch (selectedToken) {
      case SolanaSupportedTokenEnum.SOL:
        return amount * rates[0];
      case SolanaSupportedTokenEnum.JTO:
        return amount * rates[1];
      case SolanaSupportedTokenEnum.PYTH:
        return amount * rates[2];
      case SolanaSupportedTokenEnum.USDC:
        return amount * rates[3];
      default:
        return 0;
    }
  }
  // TODO: Update when implement other chain
  return 0;
};

export const getSolEnvByToken = (token: SolanaSupportedTokenEnum, env?: string) => {
  if (!env) return "";
  const splitKeys = env.split(",") || [];

  switch (token) {
    case SolanaSupportedTokenEnum.SOL:
      return splitKeys[0];
    case SolanaSupportedTokenEnum.JTO:
      return splitKeys[1];
    case SolanaSupportedTokenEnum.PYTH:
      return splitKeys[2];
    case SolanaSupportedTokenEnum.USDC:
      return splitKeys[3];
    default:
      return "";
  }
};

export const roundUp = (val: number, precision: number = 2): number => {
  return Math.ceil(val * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const maskString = (str: string) => {
  if (str.length <= 10) {
    return str;
  }
  const start = str.slice(0, 5);
  const end = str.slice(-5);
  const middle = "*".repeat(5);
  return `${start}${middle}${end}`;
};

export const deviceCheck = () => {
  if (WebApp.platform === "android" || WebApp.platform === "ios" || WebApp.platform === "android_x") {
    return "mobile";
  } else {
    return "desktop";
  }
};
